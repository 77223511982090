import styled from '@emotion/styled';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useState, useEffect, useRef } from 'react';
import useModal from '../../hooks/useModal'
import { ConfirmModal } from '../../modal';
import {RiDeleteBin6Line as Delete} from 'react-icons/ri'
import { useCookies } from 'react-cookie';
import useNotification from '../../hooks/useNotification';
import useApi from '../../hooks/useApi';
import useLoading from '../../hooks/useLoading';
import {addUser,editUser as editUserDetail} from '../../redux/reducers/hrReducer';
import { useDispatch,useSelector } from 'react-redux';
import {Select} from '../../components';


const genderList = [
  {id:1,name:'Male',value:"male"},
  {id:2,name:'Female',value:"female"},
  {id:3,name:'others',value:"others"},
]

const AddUser = ({editUser = null}) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const {showModal} = useModal();
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');
  const [username,setUsername] = useState('');
  const [password,set_password] = useState('');
  const [job_description,set_job_description] = useState('');
  const [role,set_role] = useState('');
  const [level,set_level] = useState('');
  const [email,setEmail] = useState('');
  const [gender,setGender] = useState({});
  const [groupList,setGroupList] = useState('');
  // const [cookies] = useCookies(['token','user']);
  const {showNotification} = useNotification();
  const [default_password,set_default_password] = useState(false);
  const {loading} = useLoading();
  const dispatch = useDispatch();
  const allGroups = useSelector(store => store.hrState.groups);
  const allLevels = useSelector(store => store.hrState.levels);
  const {Api,BASE_URL,cookies} = useApi();
  const [file,setFile] = useState('');
  const [logo,setLogo] = useState('');
  const image = useRef(null);

  

  // const showDeleteModal = ()=>{
  //   showModal({content:<ConfirmModal message="Are you sure you want to delete this user" ok={handleDeleteUser} cancel={closeDeleteModal} />});
  // }
  // const closeDeleteModal = ()=>{
  //   showModal({close: true});
  // }
  // const handleDeleteUser = ()=>{

  // }



  const clearForm = () =>{
    setFirstName('');
    setLastName('');
    setUsername('');
    setEmail('');
    setPhoneNumber('');
    set_level('');
    set_job_description('');
    set_role('');
    setGender('');
    setGroupList('');
    set_default_password(false);
    setFile('');
    setLogo('');
    set_password('');
  }

  const handleImage = (e) =>{
    let tmpFile = e.target.files[0];
    setLogo(e.target.value);
    let objectUrl = URL.createObjectURL(tmpFile)
    setFile(objectUrl);
  }

  const handleDefaultPassword = ()=>{
     if(!default_password){
        set_password('12345');
        set_default_password(true);
     }
     else{
      set_password('');
      set_default_password(false);
     }
  }

  const handleAddUser = (e) =>{
    e.preventDefault();
    if(firstName && lastName && username && email && gender && gender.value && phoneNumber){
      const body = new FormData();
      if(image && image.current && image.current.files[0])body.append('file',image.current.files[0]);
      body.append('firstName',firstName);
      body.append('lastName',lastName);
      body.append('username',username);
      body.append('email',email);
      body.append('gender',gender.value);
      if(level && level.id)body.append('level_id',level.id);
      if(role)body.append('role',role);
      if(job_description)body.append('job_description',job_description);
      body.append('password',password);
      body.append('phone_number',phoneNumber);
      if(groupList && groupList.length > 0) body.append('group_list',`[${groupList.map(eGroup => eGroup.id).toString()}]`);
      // body.append('business_id',cookies.user.org.id);
    
      if(editUser)body.append('id',editUser.id);
      if(Api.check_network()){
        const url = editUser?'user/update-user-details':'user/register-user';
        loading()
        Api.form_post({url,body})
        .then(data => {
          loading(true)
          if(data.isSuccessful){
            editUser && editUser.id?dispatch(editUserDetail({...editUser,
              id:editUser.id,
              image:data.data.image,
              firstName,
              lastName,
              username,
              gender,
              level,
              job_description,
              role,
              phone_number:phoneNumber,
              groups:groupList,
              email,
            })):dispatch(addUser({
              id:data.data.id,
              image:data.data.image,
              firstName,
              lastName,
              username,
              gender,
              job_description,
              role,
              level,
              password,
              phone_number:phoneNumber,
              groups:groupList,
              email,
            }));
            
            clearForm();
            
            showNotification({
              type:'success',
              message:data.message || editUser && editUser?.id?'User Edited succesfully':'User Create Successfully'
            })
          }
          else{
            showNotification({
              type:'error',
              message:data.message || "Error occured try again later"
            })
          }
        })
        .catch(err =>{
          loading(true)
          showNotification({
            type:'error',
            message:err.message || "An Error occured"
          })
        })
      }
      else{
        showNotification({
          type:'error',
          message:"No Internet Connectivity"
        })
      }
    }
    else{
      showNotification({
        type:'error',
        message:"Fill All required fields"
      })
    }

  }

  useEffect(()=>{
    if(editUser){
      // console.log(editUser,'user')
      setFirstName(editUser.firstName);
      setLastName(editUser.lastName);
      setUsername(editUser.username);
      setEmail(editUser.email);
      setPhoneNumber(editUser.phone_number);
      setGender( editUser.gender);
      set_role(editUser.role);
      setGroupList(editUser.groups);
      set_default_password(editUser.password === '12345'?true:false);
      set_level(editUser.level);
      set_job_description(editUser.job_description);
      setFile(editUser.file)
      setLogo('')

      set_password(editUser.password);
    }
    else{
      clearForm()
    }
  },[editUser])
  
 
  return (
    <Wrapper>
      <form onSubmit={handleAddUser}>
        <h2>{editUser?'Edit User':'Create User'}</h2>

        {/* <i onClick={(e)=>showDeleteModal(e)}>{editUser?<Delete/>:''}</i> */}
      
        <div>
            <img src={editUser && !logo?`${BASE_URL}/upload/users/${editUser.image}`:logo?file:''} alt="Logo" />
            <ButtonFile width="120px">
              <input ref={image} type="file" value={logo} onChange={(e) => handleImage(e)} accept='image/*' />
              <button type="button">Select Logo</button>
            </ButtonFile>
        </div>

        <label>
          <span>First Name*</span>
          <input value={firstName} onChange={(e)=>setFirstName(e.target.value)} type="text" placeholder="Enter First-name"/>
        </label>

        <label>
          <span>Last Name*</span>
          <input value={lastName} onChange={(e)=>setLastName(e.target.value)} type="text" placeholder="Enter Last-name"/>
        </label>

        <label>
          <span>UserName*</span>
          <input value={username} onChange={(e)=>setUsername(e.target.value)} type="text" placeholder="Enter Username"/>
        </label>
        <label>
          <span>Email*</span>
          <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="Enter Email"/>
        </label>
        <label>
          <span>Role*</span>
          <input value={role} onChange={(e)=>set_role(e.target.value)} type="text" placeholder="Enter Role"/>
        </label>
        <label>
          <span>Job Description*</span>
          <textarea rows="5" value={job_description} onChange={(e)=>set_job_description(e.target.value)} placeholder="Enter Job Description"></textarea>
        </label>

        <label>
          <span>Phone Number*</span>
          <PhoneInput  
            country={'ng'} 
            value={phoneNumber} 
            onChange={(value)=>setPhoneNumber(value)} 
            inputProps ={{required: true,}}
          />
        </label>

        <label>
          <span>Gender*</span>
          <Select value={'value'} defaultValue={gender} options={genderList} placeholder="Select Gender" onChange={setGender} />

        </label>

        <label>
          <span>Group</span>
          <Select label="title" defaultValue={groupList} placeholder="Select Groups" multi options={allGroups} onChange={setGroupList} />
        </label>
        <label>
          <span>Level</span>
          <Select label="title" defaultValue={level} placeholder="Select Level" options={allLevels} onChange={set_level} />
        </label>
        {!editUser ?<>
          <label className='row'>
            <input checked={default_password} onChange={handleDefaultPassword} type="checkbox" />
            <span>Use Default Password (12345)</span>
          </label>
          <label>
            <span>Password*</span>
            <input disabled={default_password} type="text" value={password} onChange={(e)=>set_password(e.target.value)} />
          </label>
        </>:''}

        <button>{editUser ?'Edit User':'Add User'}</button>
      </form>
    </Wrapper>
  )
}

export default AddUser

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* text-align: center; */
  width: 600px;
  position: relative;
  @media screen and (width <= 650px){
    width:96%;
  }
  padding:0 10px 20px ;

  & > form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:10px;
    position: relative;


    & > h2{
      text-align:center;
    }
    & > i{
      color: red;
      font-size: 25px;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
    }

    & > div:first-of-type{
      display:flex;
      align-items: flex-end;
      gap:20px;
      & > img{
        border: 1px solid #999;
        border-radius: 5px;
        width:100px;
        height:100px;
      }
    }

    & > label{
      display: flex;
      flex-direction: column;
      gap:5px;
      font-size: 14px;
      z-index: 1;

    }
    // & > div:not(:first-of-type){
    //     display: flex;
    //     gap:20px;
    //     @media screen and (width <=650px){
    //       flex-direction: column;
    //     }

    //     & > label{
    //       display: flex;
    //       flex-direction: column;
    //       gap:3px;  
    //       flex:1;

    //       & > span{
    //         font-size: 14px;
    //       }
    //     }
    //   }

    & > label > input:not([type="file"]), & > label > select, & > label > textarea{
      outline:none;
      border:1px solid ${({theme})=> theme.color.primary};
      background-color: transparent;
      width: 100%;
      padding: 8px 5px;
      border-radius: 4px;
    }
    & > label > div > input{
      border:1px solid ${({theme})=> theme.color.primary} !important;
      background-color: transparent !important;
      width: 93% !important;
      padding: 8px 5px !important;
      border-radius: 4px !important;
      margin-left: 7% !important;
    }

    & > label.row{
      flex-direction: row;
      & >input{
        width:fit-content;
      }
    }

    & > button{ 
      background-color:var(--primary-color);
      padding: 10px 0;
      border: none;
      border-radius: 4px;
      color: var(--white-color);
      font-size: 17px;
      margin-top:10px;
    }
  }
  & > div{
    position: absolute;
    top: 0px ;
    display: none;
    align-items: center;
    left: 0px;
    // right: 0px;
    height: 100%;
    width: 100%;
    background-color:${({theme})=> theme.background.backdrop};
    // box-sizing: content-box;
    box-shadow: var(--modal-shadow);
    // margin-right: -10px;
    // margin-right: -20px;

    & > div{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap:10px;
      height: 130px;
      width: 100%;
      background-color: ${({theme})=> theme.background.primary};
  
      & > span{
        font-size: 17px;
        font-weight: 700;
      }
  
      & > div{
        display: flex;
        gap: 30px;
  
        & > button{
            display: flex;
            outline: none;
            border: none;
            border-radius: 5px;
            height: 35px;
            width: 50px;
            align-items: center;
            justify-content: center;
            background-color: ${({theme})=> theme.color.primary};
            color: ${({theme})=> theme.background.primary};
            cursor: pointer
        }
      }
    }
  }
  

`

const ButtonFile =  styled.label`
  width:${props => props.width || 'fit-content'};
  overflow: hidden;
  position:relative;
  & > input{
    position: absolute;
    left:0;
    top:0;
    opacity:0;
    width:100%;
    height:100%;
  }
  & > button{
    cursor: pointer;
    background-color: ${props => props.bg || 'var(--primary-color)'};
    color:${props => props.color || 'var(--white-color)'};
    outline:none;
    display:flex;
    justify-content:center;
    align-items: center;
    gap: ${props => props.gap || '10px'};
    padding:10px 10px;
    width:100%;
    height: 100%;
    margin:0;
    border:1px solid ${props => props.border || 'var(--primary-color)'};
    border-radius: ${props => props.radius || '8px'};
  }
`