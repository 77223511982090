import { useState, useEffect, memo } from "react";
import styled from "@emotion/styled";
// import useModal from '../../hooks/useModal';
import { addKpiSettings,editKpiSetting as EditKpiSetting } from '../../redux/reducers/kpiReducer';
import useApi from "../../hooks/useApi";
import useNotification from "../../hooks/useNotification";
import useLoading from "../../hooks/useLoading";
import {Select} from '../../components';
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';

const audienceTypeList = [
  {id:1,name:'Business'},
  {id:2,name:'Level'},
  {id:3,name:'Group'},
  {id:4,name:'User'},
]
const creatortypelist = [
  {id:1, name: 'Business_Admin' },
  {id: 2, name: 'HR_Admin'},
  {id: 3, name: 'Group_Head'},
  {id: 4, name: 'Supervisor'},
  {id:5,name:'User'}
]
const cycleratelist = [
  {id:1, name: 'days' },
  {id: 2, name: 'weeks'},
  {id: 3, name: 'months'},
  {id: 4, name: 'year'}
]

const AddKpiSetting = ({ editKpiSetting = null }) => {
  const [audiences_type, set_audiences_type] = useState(null);
  const [audiences, set_audiences] = useState("");
  const [creator_type, set_creator_type] = useState("");
  const [cycle_rate, set_cycle_rate] = useState("");
  const [cycle_value, set_cycle_value] = useState("");
  const [is_enable, set_is_enable] = useState(false);
  const [level,set_level] = useState('');
  const [groupList,setGroupList] = useState('');
  const allGroups = useSelector(store => store.hrState.groups);
  const allLevels = useSelector(store => store.hrState.levels);
  // const {showModal} = useModal();
  const { Api } = useApi();
  const { loading } = useLoading();
  const { showNotification } = useNotification();
  // const dispatch = useDispatch();

  const clearForm = () => {
    set_audiences_type("");
    set_audiences("");
    set_creator_type("");
    set_cycle_rate("");
    set_cycle_value("");
    set_is_enable("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Api.check_network()) {
      if (audiences_type && creator_type && cycle_rate && cycle_value) {
             
       
        const url = `kpi/${
          editKpiSetting && editKpiSetting.id ? "edit-kpi-setting" : "create-kpi-setting"
        }`;
        const body = {
          audience_type:audiences_type.id,
          creator_type: creator_type.id,
          cycle_value: parseInt(cycle_value),
          cycle_rate,
          is_enable:  is_enable?1:0,
          // business_id:cookies.user.org.id
        };
        console.log(body,'payload')
        if (editKpiSetting && editKpiSetting.id) body.id = editKpiSetting.id;
        loading();
        Api.post({ url, body })
        .then((data) => {
          loading(true);
          console.log(data, "data");
          if (data.isSuccessful) {
            
            showNotification({
              type: "success",
              message: data.message || `kpiSetting created successfully`,
            });
            clearForm();
          } else {
            showNotification({
              type: "error",
              message: data.message || `Error Occured`,
            });
          }
        })
        .catch((error) => {
          loading(true);
          showNotification({
            type: "error",
            message: `Error Occured`,
          });
        });
         
      }
    } else {
      showNotification({
        type: "error",
        message: `Network Connectivity Failure`,
      });
    }
  };

  useEffect(() => {
    if (editKpiSetting) {
      set_audiences_type(editKpiSetting.audiences_type);
      set_audiences(editKpiSetting.audiences);
      set_creator_type(editKpiSetting.creator_type);
      set_cycle_rate(editKpiSetting.cycle_rate);
      set_cycle_value(editKpiSetting.cycle_value);
      set_is_enable(editKpiSetting.is_enable);
    }
  }, [editKpiSetting]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <h2>{editKpiSetting ? "Edit KpiSetting" : "Create KpiSetting"}</h2>

        <label>
          <span>Audiences Type*</span>
          <Select
            options={audienceTypeList}
            value="id"
            lable="name"
            defaultValue={audiences_type}
            placeholder="Select Audience Type"
            onChange={set_audiences_type} />
        </label>
       {audiences_type && (audiences_type.id == 2 || audiences_type.id == 3)? <label>
          <span>Audiences</span>
        
          
          {audiences_type.id == 3?
          <Select label="title" defaultValue={groupList} placeholder="Select Groups" multi options={allGroups} onChange={setGroupList} />
          :
          <Select label="title" defaultValue={level} placeholder="Select Level" options={allLevels} onChange={set_level} />}
       
        </label>:''}
        
          <label>
            <span>Creator Type</span>
            <Select
            options={creatortypelist}
             value="id"
            label="name"
            defaultvalue={creator_type}
            onChange={ set_creator_type} />
            
          </label>
          <label>
          <span>cycle Rate*</span>
          <Select
          options={cycleratelist}
            value="1"
            label="name"
            defaultvalue={cycle_rate}
            onChange={set_cycle_rate} />
          </label>
          <label>
          <span>Cycle Value*</span>
          <input
            type="number"
            inputMode="number"
            placeholder="Enter cycle_value"
            value={cycle_value}
            onChange={(e) => set_cycle_value(e.target.value)}
          />
        </label>
        <label>
          <span>
            is enable
            
          </span>
          <input
            type="checkbox"
            checked={is_enable}
            onChange={(e) => set_is_enable(!is_enable)}
          />
        </label>

        <button>{editKpiSetting ? "Save Edit Event" : "Submit"}</button>
      </form>
    </Wrapper>
  );
};

export default memo(AddKpiSetting);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* text-align: center; */
  width: 600px;
  @media screen and (width <= 650px) {
    width: 96%;
  }
  padding: 0 10px 20px;
  position: relative;

  & > form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    & > span {
      font-size: 25px;
      top: 0;
      right: 0;
      position: absolute;
      color: red;
      cursor: pointer;
    }
    & > h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    & > div:first-of-type {
      display: flex;
      align-items: flex-end;
      gap: 20px;
      width: 100%;

      @media screen and (width <=650px) {
        flex-direction: column;
        gap: 10px;
      }
      & > label {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 14px;
        width: 50%;
        
        @media screen and (width <=650px) {
          width: 100%;
        }
      }
    }

    & > label {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 14px;
      &:last-of-type{
        flex-direction:row;
        & > span,& > label{
          width:fit-content;
        }
      }
    }
    & > div:not(:first-of-type) {
      display: flex;
      gap: 20px;
      @media screen and (width <=650px) {
        flex-direction: column;
      }

      & > label {
        display: flex;
        flex-direction: column;
        gap: 3px;
        flex: 1;

        
        & > span {
          font-size: 14px;
        }
      }
    }

    & input:not([type="file"],[type="checkbox"],[type="radio"]),
    & select,
    & textarea {
      outline: none;
      border: 1px solid ${({ theme }) => theme.color.primary};
      background-color: transparent;
      width: 100%;
      padding: 8px 5px;
      border-radius: 4px;
    }


    i {
      color: grey;
      font-size: 13px;
    }

    & > button {
      background-color: var(--primary-color);
      padding: 10px 0;
      border: none;
      border-radius: 4px;
      color: var(--white-color);
      font-size: 17px;
      margin-top: 10px;
    }
  }
`;
