import {createContext, memo,useState} from 'react';
import { ThemeProvider  } from '@emotion/react';
import {light_theme,dark_theme} from './theme';
import ModalContextProvider from './context/ModalContext';
import NotificationContextProvider from './context/NotificationContext';
import ServiceContextProvider from './context/ServiceContext';
import LoadingProvider from './context/LoadingContext';


export const ProviderContext = createContext({})


const Providers = (props) => {
  const [theme,set_theme] = useState('light');
  
  const value = {
    theme,
    set_theme
  }
  return (
    <ProviderContext.Provider value={value}>
        <ThemeProvider theme={theme === 'light'?light_theme:dark_theme}>
            <NotificationContextProvider>
                <LoadingProvider>
                    <ModalContextProvider>
                        <ServiceContextProvider>
                            {props.children}
                        </ServiceContextProvider>
                        
                    </ModalContextProvider>
                </LoadingProvider>
            </NotificationContextProvider>
            

        </ThemeProvider>
    </ProviderContext.Provider>
    
  )
}

export default memo(Providers)