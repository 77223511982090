import React, { useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import useApi from '../hooks/useApi';
import styled from '@emotion/styled';

const InitialPage = () => {
  const {data} = useParams();
  const {set_param} = useApi();
  const navigate = useNavigate();
  useEffect(()=>{
    set_param(data)
    //console.log('initial page',data)
    localStorage.setItem('cmag-param',data);
    navigate('/');
  },[data])
  return (
    <Loader>
      Please wait ...
    </Loader>
  )
}

export default InitialPage;

const Loader = styled.main`
  display:flex;
  width:100dvw;
  height:100dvh;
  justify-content: center;
  align-items: center;
  color:var(--primary-color);
`