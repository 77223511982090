import css from './user.module.css';
import useModal from '../../hooks/useModal';
import {AddUser} from '../Users';
import { Filter,Search,Button,Toggle } from '../../components';
import styled from '@emotion/styled';
import { useState,useEffect } from 'react';
import {useSelector} from 'react-redux';
import {deleteUser} from '../../redux/reducers/hrReducer';
import { RowOption,ColOption } from '../../components';
import {ConfirmModal} from '../../modal';

const filter_list = [
  'all',
  'name',
  'email',
  'phone',
  'gender',
  'group'
]

// const list= [
//   {
//     id:1,
//     firstName:'Taiwo',
//     lastName:'Ajayi',
//     username:'teea',
//     email:'ajayi.teea@hotmail.com',
//     phoneNumber:'48484737384',
//     gender:'male',
//     group:'Hardware'
//   },
//   {
//     id:2,
//     firstName:'John',
//     lastName:'Dow',
//     username:'teea',
//     email:'matthew.free@gmail.com',
//     phoneNumber:'48484737384',
//     gender:'male',
//     group:'Software'
//   },
//   {
//     id:3,
//     firstName:'John',
//     lastName:'Dow',
//     username:'teea',
//     email:'matthew.free@gmail.com',
//     phoneNumber:'48484737384',
//     gender:'male',
//     group:'Software'
//   }
// ]


const Users = () => {
  const {showModal} = useModal();
  const [display,set_display] = useState(0);
  const [search,set_search] = useState('');
  const allUsers = useSelector(store=>store.hrState.users);
  const [filtered_events,set_filtered_events] = useState(allUsers);
  const [filter_search,set_filtered_search] = useState(0);

  const handleAddUser = ()=>{
    showModal({content:<AddUser />});
  }
  const handleEditUser = (eachUser)=>{
    showModal({content:<AddUser editUser={eachUser} />});
  }
  const showDeleteModal = (id)=>{
    showModal({content:<ConfirmModal message="Are you sure you want to delete this user" ok={handleDeleteUser} cancel={closeDeleteModal} />});
  }
  const closeDeleteModal = ()=>{
    showModal({close: true});
  }
  const handleDeleteUser = (id)=>{

  }
  
  useEffect(()=>{
    set_filtered_events(allUsers);
  },[allUsers]);

  useEffect(()=>{
    if(search){
      let search_para = filter_list[filter_search];
      //console.log(search_para,'search');
      if(search_para == 'des') search_para = 'description';
      let temp_events = [];
      if(search_para == 'all'){
        temp_events = allUsers.filter(elist=>
          Object.values(elist).join('').toLowerCase().includes(
            search.toLowerCase()));
        
      }else{
        temp_events = allUsers.filter(eList => {
          return eList[search_para].toLowerCase().includes(search.toLowerCase());
        })
      }
      set_filtered_events(temp_events);
    }
    else{
      set_filtered_events(allUsers);
    }
  },[search,filter_search])

  const options = {
    label:{
      view:false,
      edit:true,
      delete:true,
    },
  }


  return (
    <div className={css.usercontent}>
      <div className={css.userdiv1}>
        <Search placeholder='search user' onChange={set_search}/>
        <Filter list={filter_list} color="var(--primary-color)" onChange={set_filtered_search} />
        <Toggle onChange={set_display} />
        <Button onClick={(e)=>handleAddUser(e)}>Add User</Button>
      </div>
      <Container display={display}>
        {display === 0?<Row>
        <div>
          <span>Full Name</span>
          <span>Email</span>
          <span>Phone</span>
          <span>Gender</span>
          <span>Groups</span>
          <span></span>
        </div>
        <div>
          {filtered_events && filtered_events.length > 0?
          filtered_events.map(eList => 
            <div key={eList.id}>
              <div>{`${eList.firstName} ${eList.lastName}`}</div>
              <div>{eList.email}</div>
              <div>{eList.phone_number}</div>
              <div>{eList.gender?.name}</div>
              <div>{eList.groups && eList.groups.length > 0 ?eList.groups.map(eGroup=> eGroup.title).join(','):''}</div>
              <div>
                <RowOption options={{...options,click:{
                  edit:()=>handleEditUser(eList),
                  delete:()=>showDeleteModal(eList.id),
                }}} />
              </div>
            </div>
          ):''}
        </div>
        </Row>:
        <Col>
           {filtered_events.length > 0 ? filtered_events.map(eList => 
              <div key={eList.id}>
                <div>
                  <ColOption options={{...options,click:{
                    edit:()=>handleEditUser(eList),
                    delete:()=>showDeleteModal(eList.id),
                  }}} />
                </div>
                <div>{`${eList.firstName} ${eList.lastName}`}</div>
                <div>{eList.email}</div>
                <div>{eList.phone_number}</div>
                <div>{eList.gender?.name}</div>
                <div>{eList.groups && eList.groups.length > 0 ?eList.groups.map(eGroup=> eGroup.title).join(','):''}</div>
                
              </div>
            
            ):''}
        </Col>}
      </Container>
      
    </div>
  )
}

export default Users;

const Container = styled.div`
  width:100%;
  overflow: ${props => props.display == 0?'scroll auto':'hidden'} ;
  min-height: 80vh;
  background-color: ${(props) => props.display == 0?props.theme.background.primary:''};
  box-shadow: ${props => props.display == 0?'var(--modal-shadow)':''};
  margin-top:10px;
  border-radius: 5px 5px 0 0;
  display: flex;
  padding:${({display})=> display == 0 ?'10px':'20px 0px'}
`


const Row= styled.div`
  flex:1;
  & > div:first-of-type{
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 50px;
    background-color: ${({theme})=> theme.background.header };
    gap:10px;
    width:100%;
    padding:15px 10px;
    border-radius: 5px;
    & > span{
      font-weight: 500;
      min-width: 100px;
    }
    & > span:last-of-type{
      min-width: unset;
      max-width: 50px;
    }
  }
  & > div:nth-of-type(2){
    margin-top: 5px;
    display:flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 30px;
    gap:5px;
    & > div{
      display: grid;
      grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 50px;
      background-color:${({theme})=> theme.background.primary };
      border-radius: 3px;
      border:1px solid ${({theme})=> theme.color.border };
      padding:10px;
      gap:10px;
      width:100%;
      border-radius: 3px;
      & > div{
        min-width: 100px;
      }
      & > div:last-of-type{
        min-width:unset;
        max-width:50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
    }

  }
  
`


const Col = styled.div`
  display: grid;
  gap:20px;
  width:100%;
  padding:0 10px;
  grid-template-columns: repeat(3,1fr);
  align-content: start;
  & > div{
    height: fit-content;
    background-color: ${({theme})=> theme.background.primary};
    min-width:calc(33.1% - 10px);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding:10px;
    border-radius: 6px !important;
    box-shadow: var(--modal-shadow);
    border: 1px solid ${({theme})=> theme.color.dropBorder};
    position:relative;
    & > div{
      display: flex;
      gap:20px;
      padding:5px 0;
      cursor:pointer;
      &:first-of-type{
        width:fit-content;
        align-self: flex-end;
        position: absolute;
        top:0px;
        right:5px;
      }
      & > span{
        display: inline-block;
        width:80px;
        font-weight: 600;
        color:${props => props.theme.color.text};

      }
    }
    
    
  }
  @media screen and (width <= 1200px) {
    grid-template-columns: repeat(2,1fr);
  }
  @media screen and (width <= 600px) {
    grid-template-columns: 1fr;
  }
`