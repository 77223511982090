import {useState,memo,useRef, useEffect} from 'react';
import styled from '@emotion/styled';
import { FaChevronDown as DownIcon, FaChevronUp as UpIcon} from 'react-icons/fa';


const Multi = (props) => {
    const options = props.options;
    const label = props.label || 'name';
    const value = props.value || 'id';
    const defaultValue = props.defaultValue;
    const temp_placeholder = props.placeholder || 'Select Option';
    const onChange = props.onChange || function(){};
    const reset = props.reset;
    const disabled = props.disabled || false;
    const [input_value,set_input_value] = useState('');  
    const [filter_options,set_filter_options] = useState(options)
    const [selected,set_selected] = useState([]);
    const [show,set_show] = useState(false);
    const [top,set_top] = useState('');   
    const divRef = useRef(null);
    const divRef2 = useRef(null);
   
    const handleClick =(e) =>{
        if(!disabled){
            set_show(!show); 
        }
    }
    const handleSelect = (eSelected) =>{
        if(selected && selected.length > 0 && !Array.isArray(eSelected) && eSelected){
            let check = selected.find(eachSelected=> eachSelected[value] === eSelected[value])
            if(check){
                let new_selected = selected.filter(eachSelected => eachSelected[value] != eSelected[value])
                set_selected(new_selected);
                onChange(new_selected)
                return;
            }
            set_selected(selected => [...selected,eSelected]);
            onChange([...selected,eSelected]);
        }
        else if(!Array.isArray(eSelected) && eSelected){
            set_selected([eSelected]);
            onChange([eSelected]);
        }
        else if(Array.isArray(eSelected) && eSelected){
            set_selected(eSelected);
            onChange(eSelected);
        }
        set_input_value('');
    }

    const handleInput = (e) =>{
        const val = e.target.value;
        set_input_value(val);
        set_show(true);
        const filter = options.filter(eOption => eOption[label].toLowerCase().includes(val.toLowerCase()));
        if(filter.length > 0) set_filter_options(filter);
        else set_filter_options(options);
    }
    const handleClose=()=> set_show(false);
    const handleChecked = (eSelected)=>{
        let checked = false;
        if(selected && selected.length > 0){
            let temp_checked = selected.find(eachSelected => eachSelected[value] == eSelected[value]);
            if(temp_checked) checked = true;
        }
        return checked;
        
    }

    useEffect(()=>{
        if( !Array.isArray(defaultValue) && defaultValue && defaultValue[value] && defaultValue[label]){
            handleSelect(defaultValue);
        }
        else if(!Array.isArray(defaultValue) && defaultValue){
            let temp = options.find(eOption => eOption[value] == defaultValue);
            if (temp){ handleSelect(temp); return}
            handleSelect(null);
        }
        else if(Array.isArray(defaultValue) && defaultValue){
            handleSelect(defaultValue);
        }
        else{
            set_selected(null);
        }

    },[defaultValue]);

    useEffect(()=>{
        handleSelect(null);
    },[reset])

    useEffect(()=>{
        const handleOutsideClick = (e)=>{
            if(divRef && divRef.current && e && divRef2 && divRef2.current){
                const divDimensions = divRef.current.getBoundingClientRect();
                const divDimensions2 = divRef2.current.getBoundingClientRect();
                if( (e.clientX < divDimensions.left ||
                    e.clientX > divDimensions.right ||
                    e.clientY < divDimensions.top ||
                    e.clientY > divDimensions.bottom) && (e.clientX < divDimensions2.left ||
                        e.clientX > divDimensions2.right ||
                        e.clientY < divDimensions2.top ||
                        e.clientY > divDimensions2.bottom) ){
                    handleClose();
                }
                if((e.clientY > window.screen.availHeight/2) && !show){
                    set_top('1');
                }
                else if(show){
                    set_top('');
                }
            }
        }
        window.addEventListener('click',handleOutsideClick);
        return ()=> {window.removeEventListener('click',handleOutsideClick)}
    },[])

  return (
    <Wrapper ref={divRef} top={top} style={{backgroundColor:disabled?'#eee':''}}>
        <div  >
            <label  onClick={handleClick} >
                <div style={{width:selected&&selected.length > 0?'60px':'0px'}}>
                    {selected && `${selected.length>0?`${selected.length} item`:''}${selected &&selected.length>1?'s':'' }` }
                </div>
                <input style={{paddingLeft:selected&&selected.length > 0?'60px':'0px'}}  disabled={disabled} value={input_value} placeholder={temp_placeholder} onChange={handleInput} />
            </label>
            <span onClick={handleClick}>{!show?<DownIcon/>:<UpIcon />}</span>
        </div>
        {show ? <div ref={divRef2} >
            {filter_options && filter_options.length > 0 ? filter_options.map((eOption =>
                <label key={eOption[value]}>  
                    <input type="checkbox" checked={handleChecked(eOption)} onChange={()=>handleSelect(eOption)} />
                    <span>{eOption[label]}</span>
                </label>
            
            )):''}
        </div>:''}
    </Wrapper>
  )
}

export default memo(Multi);


const Wrapper = styled.div`
    width:100%;
    height:40px;
    border:1px solid var(--primary-color);
    border-radius: 3px;
    padding:3px;
    position:relative;
    z-index: 2;
    & > div{
        &:first-of-type{
            width:100%;
            display:flex;
            align-items: center;
            position: relative;
            height:100%;
            label{
                width:100%;
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                height:100%;
                & > div{
                    display: flex;
                    align-items: center;
                    position: absolute;
                    left:0;
                    top:4px;
                }
                & > input{
                    width:100%;
                    height:100%;
                    border:none;
                    outline:none;
                    position: relative;
                    z-index: 1;
                    background-color: transparent;
                }
            }
            & > span{
                position:absolute;
                right:0;
                border-left:1px solid var(--primary-color);
                display: flex;
                justify-content: center;
                align-items: center;
                height:32px;
                width:30px;
                cursor: pointer;
            }
        }
        &:nth-of-type(2){
            border:1px solid #999;
            border-radius: 3px;
            max-height: 300px;
            position: absolute;
            top:${props => props.top? 'unset':'40px'};
            bottom:${props => props.top?'40px':'unset'};
            left:0;
            width:100%;
            z-index: 1;
            background-color: #eee;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 1px;
            }
            display: flex;
            flex-direction: column;
            & > label{
                padding:8px 0;
                display: flex;
                gap:10px;
                cursor: pointer;
                & > input{
                    width:20px;
                }
                &:hover{
                    background-color: #ddd;
                }
                &:has( > input:checked){
                    background-color: #ddd;
                }
            }
        }
    }
`;