import {useState,useEffect,memo} from 'react';
import styled from '@emotion/styled';
import {RiDeleteBin6Line as DeleteIcon} from 'react-icons/ri'
import { ConfirmModal } from '../../modal';
import useModal from '../../hooks/useModal';

const CreateEvent = ({editEvent = null}) => {
  const [events,set_events] = useState(null);
  const [title,set_title] = useState('');
  const [description,set_description] = useState('');
  const [date,set_date] = useState('');
  const [time,set_time] = useState('');
  const [location,set_location] = useState('');
  const {showModal} = useModal();

  const handleDelete = () =>{

  }
  const handleCancelDelete = () =>{
    showModal({close:true});
  }
  const handleConfirmDelete = () =>{
    showModal({content:<ConfirmModal message="Are you sure you want to delete this event" ok={handleDelete} cancel={handleCancelDelete} />});
  }

  useEffect(()=>{
    if(editEvent){
      set_events(editEvent);
      set_title(editEvent.title);
      set_description(editEvent.description);
      set_date(editEvent.date);
      set_time(editEvent.time);
      set_location(editEvent.location);
    }

  },[[editEvent]])
  return (
    <Wrapper>
        <form>
           <h2>{editEvent?'Edit Event':'Create Event'}</h2>
           <span onClick={handleConfirmDelete}>{editEvent?<DeleteIcon/>:''}</span>

           <label>
              <span>Title*</span>
              <input type="text" placeholder="Enter Title" value={title}
              onChange={e=>set_title(e.target.value)}/>
           </label>

           <label>
              <span>Description*</span>
              <textarea placeholder="Enter Description" cols="30" rows="10" value={description}
              onChange={e=>set_description(e.target.value)}></textarea>
           </label>

           <div>
                <label>
                    <span>Date*</span>
                    <input type="date" value={date}
                    onChange={e=>set_date(e.target.value)}/>
                </label>

                <label>
                    <span>Time*</span>
                    <input type="time" value={time}
                    onChange={e=>set_time(e.target.value)}/>
                </label>
           </div>

           <label>
              <span>Location*</span>
              <input type="text" placeholder="Enter Location" value={location}
              onChange={e=>set_location(e.target.value)}/>
           </label>

           <button>{editEvent?'Save Edit Event':'Submit'}</button>
        </form>
    </Wrapper>
  )
}

export default memo(CreateEvent);

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* text-align: center; */
    width: 600px;
    @media screen and (width <= 650px){
      width:96%;
    }
    padding:0 10px 20px ;
    position: relative;

    & > form{
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap:10px;
      & > span{
        font-size: 25px;
        top:0;
        right: 0;
        position: absolute;
        color: red;
        cursor: pointer;
      }
      & > h2{
        text-align:center;
        margin-bottom: 30px;
      }

      & > div:first-of-type{
            display:flex;
            align-items: flex-end;
            gap:20px;
            width: 100%;
            @media screen and (width <=650px){
                flex-direction: column;
                gap:10px;
            }
            & > label{
                display: flex;
                flex-direction: column;
                gap:5px;
                font-size: 14px;
                width: 50%;
                @media screen and (width <=650px){
                    width: 100%;
                }
            }
      }

      & > label{
        display: flex;
        flex-direction: column;
        gap:5px;
        font-size: 14px;

      }
      & > div:not(:first-of-type){
          display: flex;
          gap:20px;
          @media screen and (width <=650px){
            flex-direction: column;
          }

          & > label{
            display: flex;
            flex-direction: column;
            gap:3px;  
            flex:1;

            & > span{
              font-size: 14px;
            }
          }
        }

      & input:not([type="file"]), & select, & textarea{
        outline:none;
        border:1px solid ${({theme})=> theme.color.primary};
        background-color: transparent;
        width: 100%;
        padding: 8px 5px;
        border-radius: 4px;
      }

      i{
       
        color: grey;
        font-size: 13px;
      }

      & > button{
        background-color:var(--primary-color);
        padding: 10px 0;
        border: none;
        border-radius: 4px;
        color: var(--white-color);
        font-size: 17px;
        margin-top:10px;
      }
    }

`