import {createContext,useState,useRef,memo} from 'react';
import styled from '@emotion/styled';
import {FaTimes as CancelIcon} from 'react-icons/fa';
import { useEffect } from 'react';
import logo from '../images/cmanager.png';
import {MdManageAccounts as HrIcon,MdOutlineInventory as InventoryIcon,MdMarkUnreadChatAlt as CommunicationIcon} from 'react-icons/md';
import {AiFillProject as ProjectIcon} from 'react-icons/ai';
import {TbReportMoney as FinanceIcon} from 'react-icons/tb';
import {BiLinkExternal as ExternalLinkIcon} from 'react-icons/bi';
import { createPortal } from 'react-dom';
import {OnBoarding } from '../modal';

export const ServiceContext = createContext({});
let count = 0;

const list = [
    {id:1,path:'/',name:'Finance Management',active:false,icon:<FinanceIcon/>},
    {id:2,path:'https://hr-mangement.c-manager.xyz',name:'Hr | User Management',active:true,icon:<HrIcon />},
    {id:3,path:'https://inventory-mangement.c-manager.xyz',name:'Inventory Management',active:false, icon:<InventoryIcon />},
    {id:4,name:'Communication',path:'https://communication.c-manager.xyz',active:false,icon:<CommunicationIcon />},
    {id:5,name:'Project Management',path:'https://project-mangement.c-manager.xyz',active:false,icon:<ProjectIcon />}
]

const ServiceContextProvider = (props) => {
    const dialogRef = useRef(null);
    const [onboarding,set_onboarding] = useState('service');
    const [board_content,set_board_content] = useState('Hello world');
    const children = props.children;
    const secRef = useRef(null);
    const showServices = (
        {close=false,type='service',content=<>Hello world</>}
        ) =>{
        if(dialogRef.current){

            if(close){
                dialogRef.current.close();
            }
            else{
                if(type === 'onboarding'){
                    set_onboarding(type);
                    set_board_content(content);
                }
                dialogRef.current.showModal();
            }
            
        }
    }
    const value={
        showServices,
    }

    useEffect(()=>{
        const handleOutsideModal = (e)=>{
            if(secRef && secRef.current){
                const secDimensions = secRef.current.getBoundingClientRect();
                const secWidth = secRef.current.clientWidth;
                //console.log(e.clientX,secWidth,'hello')
                if( e.clientX > secDimensions.right){
                    showServices({close:true});
                }
            }
        }
        window.addEventListener('click',handleOutsideModal);
        return () =>{window.removeEventListener('click',handleOutsideModal)}
    },[])

  return (
    <ServiceContext.Provider value={value}>
            {createPortal(
            <StyledDialog ref={dialogRef}>
                <Wrapper>
                    
                    {onboarding === 'service'?
                        <section  className="main">
                            <div ref={secRef}>
                                <div>
                                    <span 
                                        onClick={()=>showServices({close:true})}>
                                        <CancelIcon />
                                    </span>
                                    <img src={logo} />
                                </div>
                                <p>Services</p>
                                <ul>
                                    {list.map((eList) =>
                                    <li key={eList.id}>
                                        <a target="_blank"  href={eList.path} className={eList.active?'active':''}>{eList.icon}<span>{eList.name}</span>{
                                            !eList.active&&<span><ExternalLinkIcon /></span>
                                        }
                                        </a> 
                                    </li>
                                    )}
                                </ul>                            
                            </div>
                            <div></div>
                        </section>
                        : onboarding === 'onboarding'?
                        <section className="onboarding">
                            {board_content || <OnBoarding />}
                        </section>:
                        <section  className="main">
                            <div ref={secRef}>
                                <div>
                                    <span 
                                        onClick={()=>showServices({close:true})}><CancelIcon />
                                    </span>
                                    <img src={logo} />
                                </div>
                                <p>Services</p>
                                <ul>
                                    {list.map((eList) =>
                                    <li key={eList.id}>
                                        <a target="_blank"  href={eList.path} className={eList.active?'active':''}>{eList.icon}<span>{eList.name}</span>{
                                            !eList.active&&<span><ExternalLinkIcon /></span>
                                        }
                                        </a> 
                                    </li>
                                    )}
                                </ul>                            
                            </div>
                            <div></div>
                        </section>
                    }
                     
                </Wrapper>
         </StyledDialog>,document.getElementById('root-portal'))}
        
        {children}
    </ServiceContext.Provider>
    
  )
}

export default memo(ServiceContextProvider);

const Wrapper = styled.aside`
    position:fixed;
    width:100%;
    top:0;
    left:0;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y:auto;

    & > section.main{
        width:100%;
        height:100%;
        display:flex;
        & > div{
            height:100%;
            &:first-of-type{
                width:250px;
                background-color: ${({theme})=> theme.background.primary};
                display:flex;
                flex-direction: column;
                gap:2px;
                position:relative;
                & > div{
                    display: flex;
                    flex-direction: column;
                    background-color: rgba(180, 199, 255, 0.3);
                    padding:10px 10px 30px 10px;
                    align-items: center;

                    & > span:first-of-type{
                        position: relative;
                        background-color: var(--white-color);
                        color:var(--red-color);
                        border:1px solid #ddd;
                        border-radius:50px;
                        cursor:pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width:35px;
                        height:35px;
                        align-self:flex-end;
                    }
                    & >img{
                        position: relative;
                        left:-10px;
                        width:120px;
                        height: auto;

                    }
                }
                & > p{
                    margin:0;
                    padding:10px;
                    font-weight: bold;
                    font-size:20px;
                    color:${({theme})=> theme.color.primary}
                }
                & > ul{
                    list-style-type: none;
                    margin:0;
                    padding:0;
                    & > li{
                        width:100%;
                        & > a{
                            display:flex;
                            text-decoration: none;
                            align-items: center;
                            gap:10px;
                            padding:10px;
                            font-weight: 500;
                            width:100%;
                            cursor:pointer;
                            color:${({theme})=> theme.color.text};
                            &.active{
                                background-color: #f5f5f5;
                                color:var(--primary-color);
                            }
                            &:hover{
                                background-color: #f5f5f5;
                                color:var(--primary-color);

                            }
                            & > span:nth-of-type(2){
                                display: inline-block;
                                margin-left: auto;
                            }
                        }
                        
                    }
                }
            }
        }
    }
    & > section.onboarding{
        width:100%;
        height: 100%;
        background-color: var(--primary-dark-back);
        display:flex;
        justify-content: center;
        align-items: center;
    }

`;

export const StyledDialog = styled.dialog`
    border:none;
    outline:none;
    background: transparent;
    padding:0;
    margin:0;
    &::backdrop{
        background-color:${({theme})=> theme.background.backdrop};
    }
`