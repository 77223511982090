import {createContext,useState,useRef,memo,useEffect} from 'react';
import styled from '@emotion/styled';
import {FaTimes as CancelIcon} from 'react-icons/fa';
import { createPortal } from 'react-dom';

export const ModalContext = createContext({});
let count = 0;

const ModalContextProvider = (props) => {
    const [content,set_content] = useState(<>Hello world</>);
    const [cancel,set_cancel] = useState(true);
    const [dark,set_dark] = useState(false);
    const dialogRef = useRef(null);
    const children = props.children;
    const secRef = useRef(null);
    const showModal = ({close=false,
            content=<>Hello world</>,
            type=null,
            cancel=true,
            dark=false,
        }) =>{
        if(dialogRef.current){
            count++;
            if(close){
                // console.log(close,count,'close');
                set_dark(false);
                set_cancel(true);
                set_content(<>hello world</>)
                dialogRef.current.close();
                
            }
            else{
                if(dark)set_dark(true);
                if(content && !type) set_content(content);
                set_cancel(cancel)
                dialogRef.current.showModal();
            }
            
        }
    }
    const value={
        showModal,
    }

    const handleClose = ()=>{
        set_content(<>Hello world</>)
        showModal({close:true});
    }

  return (
    <ModalContext.Provider value={value}>
            {createPortal(
            <StyledDialog ref={dialogRef} dark={dark?'dark':''}>
                <Wrapper >
                    <section>
                        {cancel ?<span onClick={handleClose}><CancelIcon /></span>:''}
                        <div>
                            {content}
                        </div>
                    </section> 
                </Wrapper>
         </StyledDialog>,document.getElementById('modal-portal'))}
        
        {children}
    </ModalContext.Provider>
    
  )
}

export default memo(ModalContextProvider);

const Wrapper = styled.aside`
    position:fixed;
    width:100%;
    top:0;
    left:0;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y:auto;
    & > section:not(.main){
        width: fit-content;
        padding:10px;
        display: flex;
        position:relative;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 0;
        }
        
        & > span{
            width:40px;
            height:40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--red-color);
            color:var(--white-color);
            border-radius: 50%;
            cursor:pointer;
        }
        & > div{
            background-color: ${({theme})=> theme.background.primary};
            color:${({theme})=> theme.color.text};
            width: fit-content;
            max-width: 100%;
            padding:10px;
            border-radius: 6px;
            box-shadow: ${({theme})=> theme.background.boxShadow};
            border: 1px solid ${({theme})=> theme.color.border};            
        }
    }
`;

const StyledDialog = styled.dialog`
    border:none;
    outline:none;
    background: transparent;
    padding:0;
    margin:0;
    &::backdrop{
        background-color:${({theme,dark})=> dark?'#000c33':theme.background.backdrop};
    }
`