import React from 'react'
import { useState } from 'react';
import styled from '@emotion/styled';
import { Filter,Search,Button,Toggle } from '../../components';
import {ConfirmModal} from '../../modal';
import {deleteLevel} from '../../redux/reducers/hrReducer';
import useModal from '../../hooks/useModal';
import useApi from '../../hooks/useApi';
import { useDispatch } from 'react-redux';
import useLoading from '../../hooks/useLoading';
import useNotification from '../../hooks/useNotification';
import { RowOption,ColOption } from '../../components';
import { AddKpiScore } from '.';


const list= [
  {
    id:1,
    kpi:'Taiwo',
    user:'Ajayi',
    start_date: '6th july 2023',
    end_date: '6th july 2024',
    score:1
  },
  {
    id:2,
    kpi:'Matthew',
    user:'Osahon-Ediae',
    start_date: '10th decemeber 2023',
    end_date: '10th decemeber 2024',
    score:2
  },
  {
    id:3,
    kpi:'Taiwo',
    user:'Ajayi',
    start_date: '5th june 2023',
    end_date: '5th june 2024',
    score:3
  }
]

const KpiScore = () => {
  const [display,set_display] = useState(0);
  const {showModal} = useModal();
  const {Api} = useApi();
  const {loading} = useLoading();
  const dispatch = useDispatch();
  const {showNotification} = useNotification();

  const handleCreateEvent = ()=>{
    showModal({content:<AddKpiScore />});
  }
  const handleEditkpi = (eachKpi)=>{
    showModal({content:<AddKpiScore editKpi={eachKpi}/>})
  }

  const handleDelete = (id) =>{
    console.log(id,'id');
    if(Api.check_network()){
      const body = {
        id,
      };
      const url = `hr/delete-level`;
      loading();
      Api.post({url,body})
      .then((data)=>{
        loading(true);
        if(data.isSuccessful){
          dispatch(deleteLevel({id}));
          showNotification({
            type:'success',
            message:data.message || 'kpiscore deleted successfully'
          })
          showModal({close:true});
        }
        else{
          showNotification({
            type:'error',
            message:data.message || 'Try again Something went wrong'
          })
        }
        
      })
      .catch(error =>{
        loading(true);
        showNotification({
          type:'error',
          message:error.message || 'Error Occured'
        })
      })
    }
}

const handleCancelDelete = () =>{
  showModal({close:true});
}
const handleConfirmDelete = (id) =>{
  showModal({content:<ConfirmModal message="Are you sure you want to delete this kpiscore" ok={()=>handleDelete(id)} cancel={handleCancelDelete} />});
}

  const options = {
    label:{
      view:false,
      edit:true,
      delete:true,
    },
  }
  
  return (
    <Wrapper> 
      <div>
         <Search placeholder='Search kpi' />
         <Filter />
         <Toggle onChange={set_display} />
         <Button label="Create KPI" onClick={ handleCreateEvent }/>
      </div>  
      <Container display={display}>
        {
          display?
          <Col>
             {list.length > 0 ? list.map(eList => 
              <div key={eList.id}>
                <div>
                  <ColOption options={{...options,click:{
                    edit:()=>handleEditkpi(eList),
                    delete:()=>handleConfirmDelete(eList.id),
                  }}} />
                </div>
                <div>{eList.kpi}</div>
                <div>{eList.user}</div>
                <div>{eList.start_date}</div>
                 <div>{eList.end_date}</div>
                  <div>{eList.score || 0}</div>
              </div>
            ):''}
          </Col>:
          <Row>
            <div>
              <span>Kpi</span>
              <span>User id</span>
              <span>Start date</span>
              <span>End date</span>
              <span>Score</span>
              <span></span>
            </div>
            <div>
              {list&& list.length > 0?
              list.map(eList =>  
               <div key={eList.id}>
                <div>{eList.kpi}</div>
                <div>{eList.user}</div>
                <div>{eList.start_date}</div>
                 <div>{eList.end_date}</div>
                  <div>{eList.score || 0}</div>
                  <div>
                <RowOption options={{...options,click:{
                  edit:()=>handleEditkpi(eList),
                  delete:()=>handleConfirmDelete(eList.id),
                }}} />
              </div>
              </div>
              ):''}
            </div>
          </Row>
        }
      
      </Container> 
    </Wrapper>
  )
}
export default KpiScore;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    & > div:first-of-type{
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
        @media screen and (width <= 800px) {
        flex-wrap: wrap;
        gap:10px;
        }
        & > button:last-of-type{
            margin-left:auto;
            @media screen and (width <= 800px) {
                margin-left: 0;
            }
        }
    }
`

const Container = styled.div`
  width:100%;
  overflow: ${props => props.display == 0?'scroll auto':'hidden'} ;
  min-height: 80vh;
  background-color: ${(props) => props.display == 0?props.theme.background.primary:''};
  box-shadow: ${props => props.display == 0?'var(--modal-shadow)':''};
  margin-top:10px;
  border-radius: 5px 5px 0 0;
  display: flex;
  padding:${({display})=> display == 0 ?'10px':'20px 0px'}
`


 
const Row= styled.div`
flex:1;
& > div:first-of-type{
  display: grid;
  grid-template-columns: 1fr 1.5fr repeat(3,0.9fr) 50px;
  background-color: ${({theme})=> theme.background.header };
  gap:10px;
  width:100%;
  padding:15px 10px;
  border-radius: 5px;
  & > span{
    font-weight: 500;
    min-width: 100px;
  }
  & > span:last-of-type{
    min-width: unset;
    max-width: 50px;
  }
}
& > div:nth-of-type(2){
  margin-top: 5px;
  display:flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 30px;
  gap:5px;
  & > div{
    display: grid;
    grid-template-columns: 1fr 1.5fr repeat(3,0.9fr) 50px;
    background-color:${({theme})=> theme.background.primary };
    border-radius: 3px;
    border:1px solid ${({theme})=> theme.color.border };
    padding:10px;
    gap:10px;
    width:100%;
    border-radius: 3px;
    & > div{
      min-width: 100px;
    }
    & > div:last-of-type{
      min-width:unset;
      max-width:50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
  }

}

`

const Col = styled.div`
display: grid;
gap:20px;
width:100%;
padding:0 10px;
grid-template-columns: repeat(3,1fr);
align-content: start;
& > div{
  height: fit-content;
  background-color: ${({theme})=> theme.background.primary};
  min-width:calc(33.1% - 10px);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding:10px;
  border-radius: 6px !important;
  box-shadow: var(--modal-shadow);
  border: 1px solid ${({theme})=> theme.color.dropBorder};
  position:relative;
  & > div{
    display: flex;
    gap:20px;
    padding:5px 0;
    cursor:pointer;
    &:first-of-type{
      width:fit-content;
      align-self: flex-end;
      position: absolute;
      top:0px;
      right:5px;
    }
    & > span{
      display: inline-block;
      width:80px;
      font-weight: 600;
      color:${props => props.theme.color.text};

    }
  }
  
  
}
@media screen and (width <= 1200px) {
  grid-template-columns: repeat(2,1fr);
}
@media screen and (width <= 600px) {
  grid-template-columns: 1fr;
}
`

 