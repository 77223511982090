import {memo} from 'react';
import styled from '@emotion/styled';


const Container = (props)=>{
    const label = props.children || props.label || 'Button';
    return <button {...props}>{label}</button>
}


const Button = styled(Container)`
    border-radius: ${props => props.radius || '8px'};
    background-color: ${props => props.bg || 'var(--primary-color)'};
    color:${props => props.color || 'var(--white-color)'};
    display:flex;
    align-items: center;
    justify-content: center;
    gap: ${props => props.gap || '8px'};
    padding: ${props => props.padding || '12px 25px'};
    border:${props => props.border || 'none'};
    outline:none; 
    cursor:pointer;
`;

export default memo(Button);