import { useState, useEffect } from 'react';
import useModal from '../../hooks/useModal';
import {CreateEvent} from '../Events';
import { Filter,Search,Button,Toggle } from '../../components';
import styled from '@emotion/styled';
import { listClasses } from '@mui/material';


const filter_list = [
  'all',
  'title',
  'des',
  'date',
  'time',
  'location'
]

const list= [
  {
    id:1,
    title:'Employee of the month award',
    description:'Award giving ceremony',
    date:'2023-07-24',
    time:'14:30',
    location:'Company lobby'
  },
  {
    id:2,
    title:'Work dinner',
    description:'Employees raise',
    date:'2023-07-03',
    time:'18:00',
    location:'Dark star hotel'
  },
  {
    id:3,
    title:"Ngozi's birthday",
    description:'celebation of birth',
    date:'2023-11-03',
    time:'13:30',
    location:'Company reception'
  }

]

const Events = () => {
  const {showModal} = useModal();
  const [display,set_display] = useState(0);
  const[search,set_search] = useState('');
  const [filtered_events,set_filtered_events] = useState(list);
  const [filter_search,set_filtered_search] = useState(0);

  const handleCreateEvent = ()=>{
    showModal({content:<CreateEvent />});
  }
  const handleEvent = (eachEvent)=>{
    showModal({content:<CreateEvent editEvent={eachEvent}/>})
  }
  useEffect(()=>{
      set_filtered_events(list);
  },[list]);

  useEffect(()=>{
    if(search){
      let search_para = filter_list[filter_search];
      //console.log(search_para,'search');
      if(search_para == 'des') search_para = 'description';
      let temp_events = [];
      if(search_para == 'all'){
        temp_events = list.filter(elist=>
          Object.values(elist).join('').toLowerCase().includes(
            search.toLowerCase()));
         
      }else{
        temp_events = list.filter(eList => {
          return eList[search_para].toLowerCase().includes(search.toLowerCase());
        })
      }
      set_filtered_events(temp_events);
    }
    else{
      set_filtered_events(list);
    }
  },[search,filter_search])

  return (
    <Wrapper>
      <div>
          <Search placeholder='search event' onChange={set_search}/>
          <Filter list={filter_list} onChange={set_filtered_search} />
          <Toggle onChange={set_display} />
          <Button onClick={(e)=>handleCreateEvent(e)}>Create Event</Button>
      </div>
      <Container display={display}>
        {display == 0?<Row>
        <div>
          <span>Title</span>
          <span>Description</span>
          <span>Date</span>
          <span>Time</span>
          <span>Location</span>
        </div>
        <div>
          {filtered_events && filtered_events.length > 0?
          filtered_events.map(eList => 
            <div key={eList.id} onClick={()=>handleEvent(eList)}>
              <div>{eList.title}</div>
              <div>{eList.description}</div>
              <div>{eList.date}</div>
              <div>{eList.time}</div>
              <div>{eList.location}</div>
            </div>
          ):'No Events'}
        </div>
        </Row>:
        <Col>
           {filtered_events.length > 0 ? filtered_events.map(eList => 
              <div key={eList.id} onClick={()=>handleEvent(eList)}>
                <div>{eList.title}</div>
                <div>{eList.description}</div>
                <div>{eList.date}</div>
                <div>{eList.time}</div>
                <div>{eList.location}</div>
              </div>
            
            ):'No Events'}
        </Col>}
      </Container>
      
    </Wrapper>
  )
}

export default Events

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    & > div:first-of-type{
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
        @media screen and (width <= 800px) {
        flex-wrap: wrap;
        gap:10px;
        }
        & > button:last-of-type{
            margin-left:auto;
            @media screen and (width <= 800px) {
                margin-left: 0;
            }
        }
    }
`

const Container = styled.div`
  width:100%;
  overflow: ${props => props.display == 0?'scroll auto':'hidden'} ;
  
  background-color: ${(props) => props.display == 0?props.theme.background.primary:''};
  box-shadow: ${props => props.display == 0?'var(--modal-shadow)':''};
  margin-top:10px;
  border-radius: 5px 5px 0 0;
  display: flex;
  padding:${({display})=> display == 0 ?'':'20px 0px'}
`


const Row= styled.div`
  flex:1;
  & > div:first-of-type{
    display: grid;
    grid-template-columns: repeat(1,1fr) 1.5fr repeat(3,0.9fr);
    background-color: ${({theme})=> theme.background.header };
    gap:10px;
    width:100%;
    padding:15px 10px;
    border-radius: 5px;
    & > span{
      font-weight: 500;
      min-width: 100px;
      &:nth-of-type(3){
        min-width:200px;
      }
    }
  }
  & > div:nth-of-type(2){
    margin-top: 5px;
    display:flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 30px;
    gap:5px;
    & > div{
      display: grid;
      grid-template-columns: repeat(1,1fr) 1.5fr repeat(3,0.9fr);
      background-color:${({theme})=> theme.background.primary };
      border-radius: 3px;
      border:1px solid ${({theme})=> theme.color.border };
      padding:10px;
      gap:10px;
      width:100%;
      border-radius: 3px;
      & > div{
        min-width: 100px;
        overflow:hidden;
        word-break: keep-all;
      }
      & > div:nth-of-type(3){
        /* min-width:200px; */
        flex:1;
      }
    }

  }
  
`

const Col = styled.div`
  display: grid;
  gap:20px;
  width:100%;
  padding:0 10px;
  grid-template-columns: repeat(3,1fr);
  & > div{
    background-color: ${({theme})=> theme.background.primary};
    min-width:calc(33.1% - 10px);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding:10px;
    border-radius: 6px !important;
    box-shadow: var(--modal-shadow);
    border: 1px solid ${({theme})=> theme.color.dropBorder};
    & > div{
      display: flex;
      gap:20px;
      padding:5px 0;
      cursor:pointer;
      & > span{
        display: inline-block;
        width:80px;
        font-weight: 600;
        color:${props => props.theme.color.text};

      }
    }
    
    
  }
  @media screen and (width <= 1200px) {
    grid-template-columns: repeat(2,1fr);
  }
  @media screen and (width <= 600px) {
    grid-template-columns: 1fr;
  }
`