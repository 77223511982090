import {memo} from 'react';
import logo from '../../images/logo.png';
import styled from '@emotion/styled';
import {IoIosArrowDropleftCircle as CloseIcon, IoIosArrowDroprightCircle as OpenIcon} from 'react-icons/io';
import { useCookies } from 'react-cookie';
import useApi from '../../hooks/useApi';

// const BASE_URL = 'https://api.c-manager.xyz';

const BusProfile = ({onClick,minimize}) => {
    // const [cookies] = useCookies(['token','user']);
    const {BASE_URL,cookies} = useApi();
  return (
    <Wrapper minimize={minimize?'1':''} >
        <div>
            {!minimize?<CloseIcon onClick={onClick} />:<OpenIcon onClick={onClick} />}
        </div>
        
        <div>
            <img src={cookies?.user?.org?.business_image ?`${BASE_URL}/upload/businesses/${cookies?.user?.org?.business_image}` : logo} alt="LOGO" width="80" height="80" />
        </div>
        <div>{!minimize ?'HR | USER MGMT.':""}</div>
        
    </Wrapper>
  )
}

export default memo(BusProfile);

const Container = (props) =>{
    const children = props.children;
    return (<div {...props}>{children}</div>)
  }
  

const Wrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    padding:5px 5px 10px 5px;
    align-items:center;
    border-bottom: 1px solid var(--border-color);
    color:${({theme})=> theme.color.text};
    & > div:first-of-type{
        align-self: flex-end;
        font-size:30px;
        cursor: pointer;
        color: var(--light-blue);
        position: relative;
        right:-20px;
    }
    & > div:nth-of-type(2){
        visibility: ${props => !props.minimize ?'visible':'hidden'};
        & > img{
            object-fit: contain;
            object-position: center;
        }
    }
    & > div:nth-of-type(3){
        visibility: ${props => !props.minimize ?'visible':'hidden'};
        width:fit-content;
        overflow: hidden;
        height:21px;
        
    }



`