import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { MdOutlineMoreHoriz as MoreIcon,MdOutlineRemoveRedEye as ViewIcon } from "react-icons/md";
import { FaRegEdit as EditIcon,FaRegTrashAlt as DeleteIcon } from "react-icons/fa";

const RowOption = (props) => {
  const [open,set_open] = useState(false);
  const options = props.options;
  const divRef = useRef();
  const spanRef = useRef();

  const handleOpen = ()=>{
    set_open(!open);
  }

  const tempOptions = [
    {
      name:'view',
      label:<>
        <span><ViewIcon /></span>
        <span>View</span>
      </>,
      click:options?.click?.view || function(){}
    },
    {
      name:'edit',
      label:<>
        <span><EditIcon /></span>
        <span>Edit</span>
      </>,
      click:options?.click?.edit || function(){}
    },
    {
      name:'delete',
      label:<>
        <span><DeleteIcon /></span>
        <span>Delete</span>
      </>,
      click:options?.click?.view || function(){}
    },
  ]

  useEffect(()=>{
    const handleOutsideClick = (e) =>{
      if(spanRef && spanRef.current && divRef && divRef.current){
        const spanDimensions = spanRef.current.getBoundingClientRect();
        const divDimensions = divRef.current.getBoundingClientRect();
        if( (e.clientX < divDimensions.left ||
          e.clientX > divDimensions.right || 
          e.clientY < divDimensions.top ||
          e.clientY > divDimensions.bottom)&&(
            e.clientX < spanDimensions.left ||
              e.clientX > spanDimensions.right || 
              e.clientY < spanDimensions.top ||
              e.clientY > spanDimensions.bottom
          )){
                set_open(false);
        }
        
      }

    }
    window.addEventListener('click',handleOutsideClick)
  },[])

  return (
    <Wrapper>
        <span ref={spanRef} onClick={handleOpen} title="Action Options"><MoreIcon /></span>
        {open && tempOptions && tempOptions.length > 0 ?<div ref={divRef} >
           {tempOptions.filter(eOpt => options.label[eOpt.name]).map((eOption,index) => <div key={index} onClick={options.click[eOption.name]}>
             {eOption.label}
           </div>)}
        </div> :''}
    </Wrapper>
  )
}

export default RowOption;


const Container = (props) =>{
    return <div {...props}>{props.children}</div>
}

const Wrapper = styled(Container)`
  position: relative;
  & > span{
      display: flex;
      justify-content: center;
      align-items: center;
      color:var(--white-color);
      padding:5px;
      border-radius: 5px;
      background-color: var(--primary-color);
      font-size:20px;
      cursor: pointer;
  }
  & > div{
    padding:5px 0;
    display:flex;
    flex-direction: column;
    gap:10px;
    z-index: 1;
    background-color: ${props => props.theme.background.primary};
    border-radius: 5px;
    box-shadow: ${({theme})=> theme.background.boxShadow};
    position: absolute;
    border:1px solid ${props => props.theme.color.border};
    top:30px;
    right:0;
    & > div{
      display: flex;
      align-items: center;
      gap:10px;
      cursor: pointer;
      padding:5px 15px;
      &:hover{
        background-color: var(--high-light-blue);
      }
      & > span{
        display:flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

`