export const light_theme = {
    background:{
      primary:'var(--primary-light-back)',
      secondary:'var(--secondary-light-back)',
      boxShadow:'var(--light-shadow)',
      backdrop:'rgba(0, 6, 26,0.6)',
      header:'#eee',
  
    },
    color:{
      text:'var(--text-light-color)',
      primary:'var(--primary-light-color)',
      secondary:'',
      border:'var(--border-color)',
      dropBorder:'none',
    }
  }
  
export const dark_theme = {
    background:{
      primary:'var(--primary-dark-back)',
      secondary:'var(--secondary-dark-back)',
      boxShadow:'var(--dark-shadow)',
      backdrop:'rgba(0, 6, 26,0.8)',
      header:'var(--primary-color)',
    },
    color:{
      text:'var(--text-dark-color)',
      primary:'var(--primary-dark-color)',
      secondary:'',
      border:'var(--primary-color)',
      dropBorder:'var(--primary-color)',
  
    }
  }