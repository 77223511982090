import {useRef,useState,useEffect,memo }from 'react';
import {BsFillBellFill as BellIcon,BsTrash as DeleteIcon} from 'react-icons/bs';
import styled from '@emotion/styled';

const list = [
    {
        id:1,
        title:'Welcome',
        content:'Welcome to c-manager',
        isRead:false,
    },
    {
        id:2,
        title:'Congratulations!!',
        content:'Congratulations on creating a business',
        isRead:false,
    }
]

const Notice = () => {
    const [show,set_show] = useState(false);
    const spanRef = useRef();
    const divRef = useRef();
    const [messages,setMessages] = useState(list);
    const handleClose =() =>{
        set_show(false);
    }

    const markUnread = (id)=>{
        setMessages([...messages].map(eList => (eList.id == id ?{...eList,isRead:(eList.isRead === false?true:false)}:eList)))
    }


    useEffect(()=>{
        const handleOutsideClick = (e)=>{
            if(spanRef && spanRef.current && divRef && divRef.current){
                const spanDimensions = spanRef.current.getBoundingClientRect();
                const divDimensions = divRef.current.getBoundingClientRect();
                if( (e.clientX < divDimensions.left ||
                    e.clientX > divDimensions.right || 
                    e.clientY < divDimensions.top ||
                    e.clientY > divDimensions.bottom)&&(
                      e.clientX < spanDimensions.left ||
                        e.clientX > spanDimensions.right || 
                        e.clientY < spanDimensions.top ||
                        e.clientY > spanDimensions.bottom
                    )){
                        handleClose();
                }
                
            }
        }
        window.addEventListener('click',handleOutsideClick);

        return ()=> window.removeEventListener('click',handleOutsideClick);
    },[])

    
  return (
    <Wrapper>
        <span title="Notification" ref={spanRef} onClick={()=>set_show(!show)} style={show?{color:'var(--primary-color)'}:{color:'var(--light-blue)'}}><BellIcon /></span>
        {show ?<div ref={divRef}>
            {/* <span className='cancel' onClick={()=>set_show(false)}><CancelIcon /></span> */}
            <div>
                {messages && messages.length > 0 && messages.map(eList => 
                    <div key={eList.id} onClick={()=>markUnread(eList.id)} id={eList.id} className={eList.isRead?'':'unread'}>
                        <div className={eList.isRead?'':'unread'}>
                            {eList.title.length > 25?eList.content.slice(0,27)+'...':eList.title}
                            <span title="Read More">Read more</span>
                            <span title="Delete message"><DeleteIcon /></span>
                        </div>
                        <p>{eList.content.length > 39?eList.content.slice(0,39)+'...':eList.content}</p>
                    </div>    
                )}
            </div>
        </div>:<></>}
    </Wrapper>
  )
}

export default memo(Notice);

const Wrapper = styled.div`
    /* padding:0 20px; */
    /* border-right:1px solid var(--black-color);
    border-left:1px solid var(--black-color); */
    position:relative;
    background-color: #fafafa;
    width:40px;
    height:40px;
    border-radius: 50%;
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    & > span{
        cursor:pointer;
        font-size:20px;
        color:var(--light-blue);
    }
    & > div{
        position: absolute;
        top:22px;
        right:0px;
        @media screen and (width <= 800px) {
            right:-30px;
        }
        width:300px;
        box-shadow: var(--modal-shadow);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        background-color: ${({theme})=> theme.background.primary};
        padding:10px;
        z-index:1;
        border: 1px solid ${({theme})=> theme.color.dropBorder};
        & > span.cancel{
            cursor:pointer;
            align-self: flex-end;
            color:var(--red-color);
        }
        & > div{
            display:flex;
            flex-direction: column;
            gap:5px;
            & > div{
                border:1px solid ${({theme})=> theme.color.border};
                padding:2px 10px;
                cursor: pointer;
                border-radius: 5px;
                position: relative;
                &.unread{
                    background-color: ${({theme})=> theme.background.primary};
                }
                & > div{
                    font-weight: 600;
                    margin-bottom: 5px;
                    font-size:14px;
                    display: flex;
                    gap:5px;
                    align-items: flex-start;                    
                    &.unread{
                        color:${({theme})=> theme.color.text};
                        
                    }
                    & > span{
                        display: inline-block;
                        font-size:12px;
                        display: flex;
                        &:nth-of-type(1){
                            margin-left: auto;
                            font-size:8px;
                            height: fit-content;
                            padding:3px;
                           
                        }
                        &:nth-of-type(2){
                            width:20px;
                            height:20px;                           
                            border-radius: 50%;
                            justify-content: center;
                            align-items: center;
                            
                        }
                        &:hover{
                            color:var(--light-blue);
                        }
                       
                        
                        
                    }
                }
                & > p{
                    margin:0;
                    font-size:12px;
                    color:${({theme})=> theme.color.text};
                }
            }
        }
    }


`