import styled from '@emotion/styled';
import {RiMenu5Line as MenuIcon } from 'react-icons/ri';
import {Settings,Notice,Profile} from '.';
import {memo} from 'react';
import useServices from '../../hooks/useServices';


const Header = ({toggleMode,theme}) => {
  const {showServices} = useServices();
  const handleClick= () =>{
    showServices({});
  }
  return (
    <Wrapper>
        <div>
            <span title="View all services" onClick={handleClick}><MenuIcon /></span>
            <span>C-MANAGER</span>
        </div>
        <div>
          <Settings {...{toggleMode,theme}} />
          <Notice />
          <Profile />

        </div>
    </Wrapper>
  )
}

export default memo(Header);


const Wrapper = styled.header`
  position:fixed;
  top:0;
  left:0;
  width:100%;
  background-color: ${({theme}) => theme.background.primary};
  padding:0px 10px;
  display:flex;
  z-index: 3;
  /* box-shadow: 0 2px 15px 2px rgba(0,0,0,0.05); */
  border-bottom: 2px solid ${({theme})=> theme.color.border};
  justify-content: space-between;
  & > div:first-of-type{
    display:flex;
    gap:10px;
    align-items:center;
    padding:10px 0;
    & > span:first-of-type{
        border-radius: 50%;
        font-size:18px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid ${({theme})=> theme.color.primary};
        color:var(--light-blue);
        width:30px;
        height:30px;
        cursor:pointer;
    }
    & > span:nth-of-type(2){
      cursor:none;
      font-weight: 500;
      color:${({theme})=> theme.color.primary};
    }
  }
  & > div:nth-of-type(2){
    display:flex;
    align-items: center;
    gap:8px;
    
  }


`