import {createContext,useState,useRef,memo} from 'react';
import styled from '@emotion/styled';
import {FaTimes as CancelIcon} from 'react-icons/fa';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

export const NotificationContext = createContext({});


const NotificationContextProvider = (props) => {
    const [type,set_type] = useState('success');
    const [message,set_message] = useState('message');
    const children = props.children;
    const dialogRef = useRef(null);
    const showNotification = ({type,message,close}) =>{
        if(dialogRef.current){
            if(close){
                dialogRef.current.close();
                return;
            }
            set_type(type ?type:'error');
            set_message(message?message:'message');
            dialogRef.current.showModal();
        }
    }
    const value={
        showNotification,
    }

  return (
    <NotificationContext.Provider value={value}>
        
            {createPortal(
            <StyledDialog ref={dialogRef}>
                <Wrapper {...{type}}>
                    {<section>
                        <span onClick={()=>showNotification({close:'1'})}
                        ><CancelIcon /></span>
                        <div>
                            {message}
                        </div>
                    </section>}
                </Wrapper>
           </StyledDialog> ,document.getElementById('notification-portal'))}
        
        {children}
    </NotificationContext.Provider>
    
  )
}

export default memo(NotificationContextProvider);

const Wrapper = styled.aside`
    position: fixed;
    display:flex;
    height:100%;
    width:100%;
    top:0;
    right:0;
    width:100%;
    justify-content: flex-end;
    
    & > section{
        width:320px;
        max-width: 320px;
        min-height: 80px;
        height: fit-content;
        background-color: ${({type}) => type == 'error'?'var(--notification-error-back)':'var(--notification-success-back)' }; color: ${({type}) => type == 'error'?'var(--notification-error-color)':'var(--notification-success-color)' };
        border-radius:6px 0 6px 6px;
        padding:10px;
        display:flex;
        flex-direction: column;
        border:1px solid ${({type}) => type == 'error'?'var(--notification-error-color)':'var(--notification-success-color)' };
        & > span{
            align-self: flex-end;
            cursor: pointer;
        }
        & > div{
            font-size:12px;
        }
    }
   

`;

const StyledDialog = styled.dialog`
    border:none;
    outline:none;
    background-color:'';
    padding:0;
    margin:0;
    &::backdrop{
        background-color: var(--transparent);
    }
`


