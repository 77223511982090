import css from './group.module.css'
import useModal from '../../hooks/useModal'
import {AddGroup} from '../Groups'
import { Filter,Search,Button,Toggle } from '../../components'
import styled from '@emotion/styled';
import { useState,useEffect } from 'react';
import {deleteGroup} from '../../redux/reducers/hrReducer';
import useNotification from '../../hooks/useNotification';
import useApi from '../../hooks/useApi';
import useLoading from '../../hooks/useLoading';
import { useDispatch,useSelector } from 'react-redux';
import { RowOption,ColOption } from '../../components';
import {ConfirmModal} from '../../modal';

const filter_list = [
  'all',
  'title',
  'description',
  'users_num'
]

// const list= [
//   {
//     id:1,
//     title:'Taiwo',
//     description:'Ajayi',
//     users_num:1
//   },
//   {
//     id:2,
//     title:'Matthew',
//     description:'Osahon-Ediae',
//     users_num:2
//   },
//   {
//     id:3,
//     title:'Taiwo',
//     description:'Ajayi',
//     users_num:3
//   }
// ]

const Groups = () => {
  const {showModal} = useModal();
  const [display,set_display] = useState(0);
  const [search,set_search] = useState('');
  const allGroups = useSelector(store=>store.hrState.groups);
  const [filtered_events,set_filtered_events] = useState(allGroups);
  const [filter_search,set_filtered_search] = useState(0);   
  const {showNotification} = useNotification();
  const {loading} = useLoading();
  const dispatch = useDispatch();
  const {Api,cookies} = useApi();

  const handleAddGroup = ()=>{
    showModal({content:<AddGroup />});
  }
  const handleEditGroup = (editGroup)=>{
    showModal({content:<AddGroup {...{editGroup}}/>});
  }
  const showDeleteModal = (id)=>{
    showModal({content:<ConfirmModal message="Are you sure you want to delete this group" ok={handleDeleteGroup} cancel={closeDeleteModal} />});
  }
  const closeDeleteModal = ()=>{
    showModal({close:true});
  }
  const handleDeleteGroup = (id)=>{
    if(Api.check_network()){
      let url = `hr/delete-group`;
      let body = {
        id,
        // business_id:cookies.user.org.id
      };
      loading();
      try{
        Api.post({url,body})
          .then(data => {
            loading(true);
            if(data.isSuccessful){
              dispatch(deleteGroup({id}))
              showNotification({
                type:'success',
                message:data.message || `Group Deleted Successful`
              })
            }
            else{
              showNotification({
                type:'error',
                message:data.message || `Sorry, action could not be carried out`
              })
            }
        })
      }
      catch(err){
        loading(true);
        showNotification({
          type:'error',
          message:err.message || `Error ocurred try again`
        })

      }
      finally{
        showModal({close:true});
      }
      
    }
  }

  const options = {
    label:{
      view:false,
      edit:true,
      delete:true,
    },
  }

  useEffect(()=>{
      set_filtered_events(allGroups);
  },[allGroups]);

  useEffect(()=>{
    if(search){
      let search_para = filter_list[filter_search];
      //console.log(search_para,'search');
      if(search_para == 'des') search_para = 'description';
      let temp_events = [];
      if(search_para == 'all'){
        temp_events = allGroups.filter(elist=>
          Object.values(elist).join('').toLowerCase().includes(
            search.toLowerCase()));
         
      }else{
        temp_events = allGroups.filter(eList => {
          return eList[search_para].toLowerCase().includes(search.toLowerCase());
        })
      }
      set_filtered_events(temp_events);
    }
    else{
      set_filtered_events(allGroups);
    }
  },[search,filter_search])

  return (
    <div className={css.groupcontent}>
      <div className={css.groupdiv1}>
        <Search placeholder='search group' onChange={set_search}/>
        <Filter list={filter_list} color="var(--primary-color)" onChange={set_filtered_search} />
        <Toggle onChange={set_display} />
        <Button onClick={(e)=>handleAddGroup(e)}>Add Group</Button>
      </div>

      <Container display={display}>
        {display === 0?<Row>
        <div>
          <span>Title</span>
          <span>Description</span>
          <span>Users No</span>
          <span></span>
        </div>
        <div>
          {filtered_events && filtered_events.length > 0?
          filtered_events.map(eList =>  
            <div key={eList.id}>
              <div>{eList.title}</div>
              <div>{eList.description}</div>
              <div>{eList.users_num || 0}</div>
              <div>
                <RowOption options={{...options,click:{
                  edit:()=>handleEditGroup(eList),
                  delete:()=>showDeleteModal(eList.id),
                }}} />
              </div>
            </div>
          ):''}
        </div>
        </Row>:
        <Col >
           {filtered_events.length > 0 ? filtered_events.map(eList => 
              <div key={eList.id}>
                <div>
                  <ColOption options={{...options,click:{
                    edit:()=>handleEditGroup(eList),
                    delete:()=>showDeleteModal(eList.id),
                  }}} />
                </div>
                <div>{eList.title}</div>
                <div>{eList.description}</div>
                <div><span>Users NO :</span>{eList.users_num || 0}</div>
              </div>
            ):''}
        </Col>}
      </Container>

    </div>
  )
}

export default Groups

const Container = styled.div`
  width:100%;
  overflow: ${props => props.display == 0?'scroll auto':'hidden'} ;
  min-height: 80vh;
  background-color: ${(props) => props.display == 0?props.theme.background.primary:''};
  box-shadow: ${props => props.display == 0?'var(--modal-shadow)':''};
  margin-top:10px;
  border-radius: 5px 5px 0 0;
  display: flex;
  padding:${({display})=> display == 0 ?'10px':'20px 0px'}
`


const Row= styled.div`
  flex:1;
  & > div:first-of-type{
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr 50px;
    background-color: ${({theme})=> theme.background.header };
    gap:10px;
    width:100%;
    padding:15px 10px;
    border-radius: 5px;
    & > span{
      font-weight: 500;
      min-width: 100px;
    }
    & > span:last-of-type{
      min-width: unset;
      max-width: 50px;
    }
  }
  & > div:nth-of-type(2){
    margin-top: 5px;
    display:flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 30px;
    gap:5px;
    & > div{
      display: grid;
      grid-template-columns: 1fr 1.5fr 1fr 50px;
      background-color:${({theme})=> theme.background.primary };
      border-radius: 3px;
      border:1px solid ${({theme})=> theme.color.border };
      padding:10px;
      gap:10px;
      width:100%;
      border-radius: 3px;
      & > div{
        min-width: 100px;
      }
      & > div:last-of-type{
        min-width:unset;
        max-width:50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
    }

  }
  
`


const Col = styled.div`
  display: grid;
  gap:20px;
  width:100%;
  padding:0 10px;
  grid-template-columns: repeat(3,1fr);
  align-content: start;
  & > div{
    height: fit-content;
    background-color: ${({theme})=> theme.background.primary};
    min-width:calc(33.1% - 10px);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding:10px;
    border-radius: 6px !important;
    box-shadow: var(--modal-shadow);
    border: 1px solid ${({theme})=> theme.color.dropBorder};
    position:relative;
    & > div{
      display: flex;
      gap:20px;
      padding:5px 0;
      cursor:pointer;
      &:first-of-type{
        width:fit-content;
        align-self: flex-end;
        position: absolute;
        top:0px;
        right:5px;
      }
      & > span{
        display: inline-block;
        width:80px;
        font-weight: 600;
        color:${props => props.theme.color.text};

      }
    }
    
    
  }
  @media screen and (width <= 1200px) {
    grid-template-columns: repeat(2,1fr);
  }
  @media screen and (width <= 600px) {
    grid-template-columns: 1fr;
  }
`