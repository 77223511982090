import React,{ useEffect, useState,useContext } from 'react';
import { Header,Sidenav} from './components';
import styled from '@emotion/styled';
import {AccountRouter} from './Router';
import { ProviderContext } from './Providers';
import useServices from './hooks/useServices';
import useApi from './hooks/useApi';
import { useDispatch } from 'react-redux';
import {Routes,Route} from 'react-router-dom';
import { OnBoarding } from './modal';
import {setBusinesses,
        setUsers,
        setGroups,
        setPrivileges,
        setLevels,
        setEvents} 
from './redux/reducers/hrReducer';


let count = 0;
let timer = null;
let interval = 1000;
let expire = 3*3600;
let start = false;


const genderList = [
  {id:1,name:'Male',value:"male"},
  {id:2,name:'Female',value:"female"},
  {id:3,name:'others',value:"others"},
]


const App2 = () => {
  // const [] = useCookies(['token','user']);
  const {theme,set_theme} = useContext(ProviderContext);
  const [initial_wait,set_initial_wait] = useState(true);
  const [time,set_time] = useState(0);
  const dispatch = useDispatch();
  const {Api,LOGIN_PAGE,cookies,set_cookies,
    param,set_date,fetch_status,set_fetch_status,
  } = useApi();
  const {showServices} = useServices();



  const fetchAllData = () =>{
      // console.log(tempCount++,'start')
      if(Api.check_network()){
        const url = `user-data/get-hr-data`;
        const body = {
          data:param,
        }
        Api.post({url,body},false)
        .then(data =>{
          if(data.isSuccessful){
            console.log(data,'initial data')
            if(data.more_info){
              set_cookies({
                user:data.more_info.user,
                token:data.more_info.token
              });
              set_date(new Date(data.more_info.date)); 
            }
            if(data.businesses && data.businesses.value.length >= 0){
              dispatch(setBusinesses(data.businesses.value))
            }
            if(data.users && data.users.value.length >= 0){
              let temp_users = data.users.value;
              temp_users = temp_users.map(eUser =>{
                  let gender = genderList.find(eGender => eGender.value == eUser.gender)
                  let level = eUser.level_id;
                  if(data.levels.value.length > 0){
                    level = data.levels.value.find(eLevel => eLevel.id == eUser.level_id);
                  }
                  let groups = eUser.groups;
                  groups = groups.map(eGroup => {
                     let newGroup = data.groups.value.find(e => e.id == eGroup);
                     return newGroup;
                  })
                  return {
                    ...eUser,
                    groups,
                    gender,
                    level
      
                  }
              })
              dispatch(setUsers(temp_users))
            }
            if(data.groups && data.groups.value.length >= 0 ){
              let temp_groups = data.groups.value;
  
              temp_groups = temp_groups.map(eGroup =>{
                 let tempPrivileges = eGroup.privileges;
                 tempPrivileges= tempPrivileges.map(id => {
                    let priv = data.privileges.value.find(ePriv=>ePriv.id === id);
                    return priv;
                 })
                 return {
                  ...eGroup,privileges:tempPrivileges,
                 }
              })
              dispatch(setGroups(temp_groups))
            }
            if(data.privileges && data.privileges.value.length >= 0){
              dispatch(setPrivileges(data.privileges.value))
            }
            if(data.levels && data.levels.value.length >= 0){
               dispatch(setLevels(data.levels.value));
            }
            set_initial_wait(false);
            if(data.more_info.user.businessCount == 0){
              showServices({type:'onboarding',content:<OnBoarding />})
            }
          }
        })
        .catch(err => {
          console.log(err,'error')
        })
      }
  }

  const toggleMode = ()=>{
      window.localStorage.setItem('cmanager-theme',theme === 'light'?'dark':'light');
      set_theme((theme) =>theme === 'light'?'dark':'light');
  }  

  useEffect(()=>{
      const initializeScreenMode = () =>{
          let mode = 1;
          let temp_mode = window.localStorage.getItem('cmanager-theme');
          mode = (typeof temp_mode == 'object' || typeof temp_mode == 'undefined') ? 'light' : temp_mode;
          set_theme(mode)
      }
        
      if(count === 0){
        count++;
        initializeScreenMode();
      }
    
  },[])

  useEffect(()=>{
    if(param && fetch_status){
      
      fetchAllData();
      set_fetch_status(false);
    }
    //console.log(fetch_status,'changed')
  },[param,fetch_status])


  const handleInterval = ()=>{
    timer = setInterval(()=>{
      set_time(time => time +1);
    },interval)
  }

  useEffect(()=>{
    if(!start){
      handleInterval();
      start = true;
    }
    if(time > expire){
      clearInterval(timer);
      window.location.href = LOGIN_PAGE;
    }
    else if(time + 120 >= expire){
      let url = 'account/re-authenticate';
      if(Api.check_network()){
        Api.get(url)
        .then(data => {
          if(data.token){
              set_cookies({token:data.token})
          }
        })
        .catch(err =>{
          console.log(err,'error')
        })
      }
      
    }
  },[time])

  return (
    <>
      {cookies?.token && !initial_wait?<>
        
        <Header {...{toggleMode,theme}} />
        <Wrapper>
              <Sidenav />
              <AccountRouter />
        </Wrapper>
      </>
      :<Loader>
        Loading ...
      </Loader>}
    </>
    
  )
}

export default App2;

const Wrapper = styled.main`
    position:fixed;
    top:51px;
    left:0;
    width:100%;
    height:calc(100vh - 51px);
    display: flex;
    & > div{
      height:100%;
    }
    @media screen and (width <= 800px) {
      flex-direction: column-reverse;
      & > div:first-of-type{
        height:fit-content;
      }
    }
`
const Loader = styled.main`
  display:flex;
  width:100dvw;
  height:100dvh;
  justify-content: center;
  align-items: center;
  color:var(--primary-color);
`