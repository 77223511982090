import {useRef,useState,useEffect,memo }from 'react';
import {FaUserCircle as UserIcon} from 'react-icons/fa';
import {IoMdArrowDropdown as DownIcon} from 'react-icons/io';
import {BiUserCircle as UserIcon2,BiLockOpen as ChangePasswordIcon, BiLogOut as LogoutIcon} from 'react-icons/bi';
import {BsWallet2 as WalletIcon} from 'react-icons/bs';
import styled from '@emotion/styled';
import useNotification from '../../hooks/useNotification';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import useApi from '../../hooks/useApi';


const Profile = () => {
    const [show,set_show] = useState(false);
    const spanRef = useRef();
    const divRef = useRef();
    const {showNotification} = useNotification();
    const {LOGIN_PAGE,cookies,set_cookies} = useApi();
    const navigate = useNavigate();
    const handleClose =() =>{
        set_show(false);
    }

    const handleLogOut = () =>{
      localStorage.clear();
      set_cookies({user:null,token:null});
      window.location.href = LOGIN_PAGE;
    }

    const list = [
      {
        id:1,
        name:cookies?.user?.name || 'username',
        icon:<UserIcon2 
        />,
        onClick:function(){},
      },
      {
        id:2,
        name:'Wallet',
        icon:<WalletIcon />,
        onClick:function(){},
      },
      {
        id:3,
        name:'Change Password',
        icon:<ChangePasswordIcon />,
        onClick:function(){},
      },
      {
        id:4,
        name:'Logout',
        icon:<LogoutIcon onClick={handleLogOut} />,
        onClick:handleLogOut,
      },
    ]


    useEffect(()=>{
        const handleOutsideClick = (e)=>{
            if(spanRef && spanRef.current && divRef && divRef.current){
                const spanDimensions = spanRef.current.getBoundingClientRect();
                const divDimensions = divRef.current.getBoundingClientRect();
                if( (e.clientX < divDimensions.left ||
                  e.clientX > divDimensions.right || 
                  e.clientY < divDimensions.top ||
                  e.clientY > divDimensions.bottom)&&(
                    e.clientX < spanDimensions.left ||
                      e.clientX > spanDimensions.right || 
                      e.clientY < spanDimensions.top ||
                      e.clientY > spanDimensions.bottom
                  )){
                        handleClose();
                }
                
            }
        }
        window.addEventListener('click',handleOutsideClick);

        return ()=> window.removeEventListener('click',handleOutsideClick);
    },[])

    
  return (
    <Wrapper>
        <div ref={spanRef} onClick={()=>set_show(!show)} title="User Profile">
          <span><UserIcon /></span>
          <span>Hi {cookies?.user?.first_name || cookies?.user?.name || 'user'}</span>
          <span><DownIcon /></span>
        </div>
        {show ?<div ref={divRef}>
            {/* <span className='cancel' onClick={()=>set_show(false)}><CancelIcon /></span> */}
            {list && list.length > 0 &&
               list.map(eList => <a key={eList.id} onClick={eList.onClick}>
                <span>{eList.icon}</span>
                {eList.name}
               </a>)
            }

        </div>:<></>}
    </Wrapper>
  )
}

export default memo(Profile);

const Wrapper = styled.div`
    padding:0 5px;
    position:relative;
    background-color: #fafafa;
    padding:5px 10px;
    border-radius: 5px;
    & > div:first-of-type{
        display:flex;
        align-items: center;
        gap:10px;
        & > span{
          cursor: pointer;
        }
        & > span:first-of-type{
          font-size:20px;
        }
        & > span:last-of-type{
          font-size: 20px;
          height:25px;
        }
        @media screen and (width <= 800px) {
          & > span:not(:first-of-type){
            display:none;
          }
        }
    }
    & > div:nth-of-type(2){
        position: absolute;
        top:22px;
        right:0;
        width:200px;
        box-shadow: var(--modal-shadow);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        background-color: ${({theme})=> theme.background.primary};
        padding:10px 0;
        border: 1px solid ${({theme})=> theme.color.dropBorder};
        & > span.cancel{
            cursor:pointer;
            align-self: flex-end;
            color:var(--red-color);
            margin-right:10px;
        }
        & > a{
          cursor:pointer;
          display:flex;
          gap:10px;
          padding:10px;
          color:${({theme})=> theme.color.text};
          font-size:14px;
          & > span{
            font-size:16px;
          }
          &:hover{
            background-color: var(--light-blue);
            color:var(--white-color);
          }

        }
    }


`