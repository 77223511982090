import { useEffect, useState,memo } from 'react'
import styled from '@emotion/styled';

const Switch = ({mode,onClick}) => {
  const [status,set_status] = useState(mode);
  const click = onClick || function(){};
  const handleClick = () =>{
       click(!status);
       set_status(!status);
  }
  useEffect(()=>{
    set_status(mode);
  },[mode])
  return (
    <Wrapper status={status?'1':''} onClick={handleClick}>
      <div></div>
      <span></span>
    </Wrapper>
  )
}

export default memo(Switch);

const Container = (props) =>{
  const children = props.children;
  return <span {...props}>{children}</span>

}

const Wrapper = styled(Container)`
  border:1px solid var(--primary-color);
  width:30px;
  height: 18px;
  border-radius: 10px;
  position: relative;
  background-color: ${props => props.status?'var(--primary-color)':'#f2f2f2'};
   
  & > span{
    position: absolute;
    top:-1px;
    left:${props=> props.status?'calc(100% - 18px)':'0'};
    display:flex;
    width:18px;
    height:18px;
    border-radius: 50%;
    background-color: var(--light-blue);
    transition:all 0.3s linear;
  }

`