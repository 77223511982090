import React from 'react';
import {Routes,Route} from 'react-router-dom';
import { InitialPage } from '../views';
import MainPage from '../App2';

const InitialRouter = () => {
  return (
    <>
        <Routes>
            <Route path="/account/user/:data" element={<InitialPage />} />
            <Route path="/*" element={<MainPage />}/>
        </Routes>  
    </>
  )
}

export default InitialRouter