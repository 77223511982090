import {memo} from 'react';
import Single from './Single';
import Multi from './Multi';

const Select = (props) => {
    const multi = props.multi;
  return (
    <>
        {multi ?<Multi {...props} />:<Single {...props} />}
    </>
  )
}

export default memo(Select)