import {useState,useEffect,memo} from 'react';
import styled from '@emotion/styled';
import useModal from '../../hooks/useModal';
import { addLevel,editLevel as EditLevel } from '../../redux/reducers/hrReducer';
import useApi from '../../hooks/useApi';
import useNotification from '../../hooks/useNotification';
import useLoading from '../../hooks/useLoading';
import { useDispatch } from 'react-redux';


const AddLevel = ({editLevel = null}) => {
  const [title,set_title] = useState('');
  const [description,set_description] = useState('');
  const [rank,set_rank] = useState('');
  const {showModal} = useModal();
  const {Api} = useApi();
  const {loading} = useLoading();
  const {showNotification} = useNotification();
  const dispatch = useDispatch();

    const clearForm = ()=>{
    set_title('');
    set_description('');
    set_rank('');
  }
  

  const handleSubmit = (e) =>{
    e.preventDefault();
    if(Api.check_network()){
      if(rank && title && description){
        try{
          const url = `hr/${editLevel && editLevel.id?'edit-level':'create-new-level'}`;
          const body = {
            title,
            description,
            rank,
            // business_id:cookies.user.org.id
          }
          if(editLevel && editLevel.id)body.id = editLevel.id;
          loading();
          Api.post({url,body})
          .then((data)=>{
            loading(true);
            console.log(data,'data');
            if(data.isSuccessful){
              let newLevel = {
                title,description,rank
              }
              if(!editLevel) {
                newLevel.id = data.data.id;
                dispatch(addLevel(newLevel));
              }
              else{
                newLevel.id = editLevel.id;
                dispatch(EditLevel(newLevel));
              }
              showNotification({
                type:'success',
                message:data.message || `Level created successfully`
              })
              clearForm();
            }
            else{
            showNotification({
              type:'error',
              message:data.message || `Error Occured`
            })
            }

          })
          .catch(error =>{
            loading(false);
            showNotification({
              type:'error',
              message:`Error Occured`
            })
          })

        }
        catch(error){
          loading(false);
          showNotification({
            type:'error',
            message:`Error Occured`
          })
        }
      }
    }
    else{
      showNotification({
        type:'error',
        message:`Network Connectivity Failure`
      })
       
    }
  }

  useEffect(()=>{
    if(editLevel){
      set_title(editLevel.title);
      set_description(editLevel.description);
      set_rank(editLevel.rank);
    }

  },[editLevel])

  return (
    <Wrapper>
        <form onSubmit={handleSubmit}>
           <h2>{editLevel?'Edit Level':'Create Level'}</h2>
           
           <label>
              <span>Title*</span>
              <input type="text" placeholder="Enter Title" value={title}
              onChange={e=>set_title(e.target.value)}/>
           </label>

           <label>
              <span>Description*</span>
              <textarea placeholder="Enter Description" cols="30" rows="6" value={description}
              onChange={e=>set_description(e.target.value)}></textarea>
           </label>
           <label>
              <span>Rank*</span>
              <input type="number" inputMode='numeric' placeholder="Enter Rank" value={rank}
              onChange={e=>set_rank(e.target.value)}/>
           </label>

          

           <button>{editLevel?'Save Edit Event':'Submit'}</button>
        </form>
    </Wrapper>
  )
}

export default memo(AddLevel);

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* text-align: center; */
    width: 600px;
    @media screen and (width <= 650px){
      width:96%;
    }
    padding:0 10px 20px ;
    position: relative;

    & > form{
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap:10px;
      & > span{
        font-size: 25px;
        top:0;
        right: 0;
        position: absolute;
        color: red;
        cursor: pointer;
      }
      & > h2{
        text-align:center;
        margin-bottom: 30px;
      }

      & > div:first-of-type{
            display:flex;
            align-items: flex-end;
            gap:20px;
            width: 100%;
            @media screen and (width <=650px){
                flex-direction: column;
                gap:10px;
            }
            & > label{
                display: flex;
                flex-direction: column;
                gap:5px;
                font-size: 14px;
                width: 50%;
                @media screen and (width <=650px){
                    width: 100%;
                }
            }
      }

      & > label{
        display: flex;
        flex-direction: column;
        gap:5px;
        font-size: 14px;

      }
      & > div:not(:first-of-type){
          display: flex;
          gap:20px;
          @media screen and (width <=650px){
            flex-direction: column;
          }

          & > label{
            display: flex;
            flex-direction: column;
            gap:3px;  
            flex:1;

            & > span{
              font-size: 14px;
            }
          }
        }

      & input:not([type="file"]), & select, & textarea{
        outline:none;
        border:1px solid ${({theme})=> theme.color.primary};
        background-color: transparent;
        width: 100%;
        padding: 8px 5px;
        border-radius: 4px;
      }

      i{
       
        color: grey;
        font-size: 13px;
      }

      & > button{
        background-color:var(--primary-color);
        padding: 10px 0;
        border: none;
        border-radius: 4px;
        color: var(--white-color);
        font-size: 17px;
        margin-top:10px;
      }
    }

`