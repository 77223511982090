import { createSlice } from "@reduxjs/toolkit";

export const hrSlice = createSlice({
    name: 'hrState',
    initialState:{
        businesses: [],
        users:[],
        groups:[],
        privileges:[],
        events:[],
        levels:[],
    },
    reducers:{
        setBusinesses: (state,action)=>{
            return {
                ...state,businesses:action.payload
            }   
        },
        addBusiness: (state,action)=>{
            return {
                ...state,businesses:[
                    action.payload,
                    ...state.businesses
                ]
            }   
        },
        editBusiness:(state,action)=>{
            let tempBusiness = state.businesses.map(eBusiness => eBusiness.id != action.payload.id?{...eBusiness,...action.payload}:eBusiness);
            return{
                ...state,businesses:tempBusiness
            }
            
        },
        setUsers: (state,action)=>{
            return {
                ...state,users:action.payload
            }   
        },
        addUser: (state,action) =>{
            return{
                ...state,users:[
                    action.payload,
                    ...state.users
                ]
            }
        },
        editUser:(state,action)=>{
            let tempUsers = state.users.map(eUser => eUser.id != action.payload.id?action.payload:eUser);
            return{
                ...state,users:tempUsers
            }
            
        },
        deleteUser:(state,action)=>{
            let newUser = state.users.filter(eUser => eUser.id != action.payload.id);
            return{
                ...state,users:newUser
            }
            
        },
        setLevels:(state,action)=>{
            return {
                ...state,levels:action.payload
            }
        },
        addLevel:(state,action)=>{
            return{
                ...state,levels:[action.payload,
                    ...state.levels
                ]
            }
        },
        editLevel:(state,action)=>{
            let newLevels = state.levels.map(eLevel => eLevel.id === action.payload.id? {...eLevel,...action.payload}:eLevel);
            return {
                ...state,levels:newLevels
            }
        },
        deleteLevel:(state,action)=>{
            return {
                ...state,levels:state.levels.filter(eLevel => 
                    eLevel.id != action.payload.id)
            }
        },
        setGroups: (state,action)=>{
            return {
                ...state,groups:action.payload
            }   
        },
        addGroup: (state,action) =>{
            return{
                ...state,groups:[
                    action.payload,
                    ...state.groups
                ]
            }
        },
        editGroup:(state,action)=>{
            let tempGroups = state.groups.map(eGroup => eGroup.id === action.payload.id?action.payload:eGroup);
            return{
                ...state,groups:tempGroups
            }
            
        },
        deleteGroup:(state,action)=>{
            let newGroup = state.groups.filter(eGroup => eGroup.id != action.payload.id);
            return{
                ...state,groups:newGroup
            }
        },
        setPrivileges: (state,action)=>{
            return {
                ...state,privileges:action.payload
            }   
        },
        setEvents: (state,action)=>{
            return {
                ...state,events:action.payload
            }   
        },
        addEvent:(state,action)=>{
            return {
                ...state,events:[
                    action.payload,
                    ...state.events
                ]
            }
        },
        editEvent:(state,action)=>{
            let newEvents = state.events.map(eEvent => eEvent.id === action.payload.id ? {...eEvent,...action.payload}:eEvent);
            return{
                ...state,events:newEvents
            }
        },
        deleteEvent:(state,action)=>{
            let newEvents = state.events.filter(eEvent => eEvent.id != action.payload.id);
            return {
                ...state,events:newEvents
            }
        }
    }
})

export const {setBusinesses,addBusiness,editBusiness,setUsers,addUser,editUser,deleteUser,setGroups,addGroup,editGroup,deleteGroup,setPrivileges,setEvents,addEvent,editEvent,deleteEvent,setLevels,addLevel,editLevel,deleteLevel} = hrSlice.actions;
export default hrSlice.reducer;