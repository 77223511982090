import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/root.css';
import './css/animation.css';
import {InitialRouter} from './Router';
import {Provider} from 'react-redux';
import store from "./redux/store";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import {CookiesProvider} from 'react-cookie';
import ApiContextProvider from './context/ApiContext';
import Providers from './Providers';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider defaultSetOptions={{ path: '/', domain: '.c-manager.xyz', expires: new Date(new Date().getTime() + 3*3600000) }}>
    <React.StrictMode>
      <Provider store={store}>
        <ApiContextProvider>
            <Router>
              <Routes>
                <Route path="*" element={
                  <Providers>
                    <InitialRouter />
                  </Providers>
                }/>
              </Routes>
            </Router>
        </ApiContextProvider>
      </Provider> 
    </React.StrictMode>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
