import styled from '@emotion/styled';
import React from 'react';
import { useSelector } from 'react-redux';
import useApi from '../hooks/useApi';
import useModal from '../hooks/useModal';
import ConfirmModal from './ConfirmModal';
import useNotification from '../hooks/useNotification';
import useLoading from '../hooks/useLoading';
import { useNavigate } from 'react-router-dom';

const Businesses = () => {
    const {businesses} = useSelector(store => store.hrState);
    const {BASE_URL,Api,cookies,set_cookies,set_fetch_status} = useApi();
    const {showModal} = useModal();
    const {showNotification} = useNotification();
    const {loading} = useLoading();
    const navigate = useNavigate();

    const ok = (data) =>{
        if(Api.check_network()){
            showModal({close:true});
            loading();
            const url = `business/switch-business`;
            const body = {}
            body.id = data.id;
            body.business_id = data.business_id;
            Api.post({body,url})
            .then(result => {
                loading(true);
                console.log(result,'switch result');
                if(result.isSuccessful){
                    showNotification({
                        type:'success',
                        message:result.message || 'Switched successfully'
                    })
                    set_cookies({
                        token:result.token,
                        user:{
                            ...cookies.user,
                            org:result.data
                        }
                    })

                    set_fetch_status(true);
                    navigate('/');
                }
                else{
                    showNotification({
                        type:'error',
                        message:result.message || 'Action was not successful'
                    })
                }
                
            })
            .catch(error => {
                loading(true)
                showNotification({
                    type:'error',
                    message:'No Internet Connectivity'
                })
                
            })

        }
        else{
            showNotification({
                type:'error',
                message:'No Internet Connectivity'
            })
        }
    }
    const cancel = () =>{
        showModal({close:false})
    }

    const handleSwitchBusiness = (data) =>{
        if(data.id && data.business_id){
            showModal({
                content:<ConfirmModal message={`Are you sure you want to switch to ${data.name}`} cancel={cancel} ok={()=>ok(data)} />
            })
        }
    }
  return (
    <Wrapper>
        {
            businesses && businesses.length > 0 ?
            businesses.map((eBusiness) => <div key={eBusiness.id} onClick={()=>handleSwitchBusiness({...eBusiness})}>
                <img src={eBusiness.image?`${BASE_URL}/upload/businesses/${eBusiness.image}`:'/bus-logo.png'} width="40px" height="40px" /><span>{eBusiness.name}</span>
            </div>):''
        }
    </Wrapper>
  )
}

export default Businesses;

const Wrapper = styled.div`
    min-width: 360px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.background.primary};
    gap:10px;
    display: flex;
    flex-direction: column;
    & > div{
        flex:1;
        padding:10px;
        border-radius: 4px;
        cursor: pointer;
        display:flex;
        align-items: center;
        gap:20px;
        color:${props => props.theme.color.text};
        border: .5px solid ${({theme})=> theme.color.border};
        &:hover{
            background-color: var(--high-light-blue);
        }
        & > img{
            object-fit: contain;
            object-position: center;
        }
    }
    
`