import {createContext,useState,useRef,memo} from 'react';
import styled from '@emotion/styled';

import { createPortal } from 'react-dom';
import {Loading } from '../modal';

export const LoadingContext = createContext({});


const LoadingProvider = ({children}) => {
    const dialogRef = useRef(null);
    const loading = (close) =>{
        if(dialogRef.current){
            // console.log(close,'close');
            if(close){
                dialogRef.current.close();
                return;
            }
            dialogRef.current.showModal(); 
        }
    }
    const value={
        loading
    }

  return (
    <LoadingContext.Provider value={value}>
            {children}
            {createPortal(
            <StyledDialog ref={dialogRef}>
                <Loading />
            </StyledDialog>,
            document.getElementById('root-portal'))}
        
    </LoadingContext.Provider>
    
  )
}
export default memo(LoadingProvider);

export const StyledDialog = styled.dialog`
    border:none;
    outline:none;
    background: transparent;
    padding:0;
    margin:0;
    &::backdrop{
        background-color:${({theme})=> theme.background.backdrop};
    }
`