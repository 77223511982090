import useModal from '../../hooks/useModal'
import { Filter,Search,Toggle } from '../../components'
import styled from '@emotion/styled';
import { useState,useEffect } from 'react';
import css from './priv.module.css'
import { ViewPrivilege } from '../Privileges';
import { useSelector } from 'react-redux';

const filter_list = [
  'all',
  'name',
  'description',
]

const list= [
  {
    id:1,
    title:'Taiwo',
    description:'Ajayi',
  },
  {
    id:2,
    title:'Matthew',
    description:'Osahon-Ediae',
  },
  {
    id:3,
    title:'Taiwo',
    description:'Ajayi',
  }

]

const Privileges = () => {
  const all_privileges = useSelector(store => store.hrState.privileges)
  const {showModal} = useModal();
  const [display,set_display] = useState(0);
  const [search,set_search] = useState('');
  const [filtered_events,set_filtered_events] = useState(all_privileges);
  const [filter_search,set_filtered_search] = useState(0);
  

  const handleViewPrivilege = (eachPrivilege)=>{
    showModal({content:<ViewPrivilege viewPrivilege={eachPrivilege}/>});
  }

  useEffect(()=>{
      set_filtered_events(all_privileges);
  },[all_privileges]);

  useEffect(()=>{
    if(search){
      let search_para = filter_list[filter_search];
      //console.log(search_para,'search');
      if(search_para == 'des') search_para = 'description';
      let temp_events = [];
      if(search_para == 'all'){
        temp_events = all_privileges.filter(elist=>
          Object.values(elist).join('').toLowerCase().includes(
            search.toLowerCase()));
         
      }else{
        temp_events = all_privileges.filter(eList => {
          return eList[search_para].toLowerCase().includes(search.toLowerCase());
        })
      }
      set_filtered_events(temp_events);
    }
    else{
      set_filtered_events(all_privileges);
    }
  },[search,filter_search])

  return (
    <div className={css.groupcontent}>
      <div className={css.groupdiv1}>
        <Search placeholder='search privilege' onChange={set_search}/>
        <Filter list={filter_list} color="var(--primary-color)" onChange={set_filtered_search} />
        <Toggle onChange={set_display} />
      </div>

      <Container display={display}>
        {!display ?<Row>
        <div>
          <span>Name</span>
          <span>Description</span>
        </div>
        <div>
          {filtered_events && filtered_events.length > 0?
          filtered_events.map(eList =>  
            <div onClick={(e)=>handleViewPrivilege(eList)} key={eList.id}>
              <div>{eList.name}</div>
              <div>{eList.description}</div>
            </div>
          ):''}
        </div>
        </Row>:
        <Col >
           {filtered_events.length > 0 ? filtered_events.map(eList => 
              <div onClick={(e)=>handleViewPrivilege(eList)} key={eList.id}>
                <div>{eList.name}</div>
                <div>{eList.description}</div>
              </div>
            ):''}
        </Col>}
      </Container>
    </div>
  )
}

export default Privileges

const Container = styled.div`
  width:100%;
  overflow: ${props => props.display === 0?'scroll auto':'hidden'} ;
  margin-top:10px;
  display: flex;
  padding:${({display})=> display === 0 ?'':'20px 0px'};
`;


const Row = styled.div`  
  flex:1;
  & > div:first-of-type{
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    background-color: ${({theme})=> theme.background.header };
   
    gap:10px;
    width:100%;
    padding:15px 10px;
    border-radius: 5px;
    & > span{
      font-weight: 500;
      min-width: 150px;
      &:nth-of-type(2){
        min-width:230px;
      }
    }
  }
  & > div:nth-of-type(2){
    margin-top:5px;
    display:flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 30px;
    gap:5px;
    & > div{
      background-color:${({theme})=> theme.background.primary };
      border-radius: 3px;
      border:1px solid ${({theme})=> theme.color.border };
      display: grid;
      grid-template-columns: 1fr 1.5fr 1fr;
      padding:10px;
      gap:10px;
      width:100%;
      cursor: pointer;
      & > div{
        min-width: 150px;
      }
      & > div:nth-of-type(2){
        min-width:250px;
      }
        
      
    }

  }
  
`

const Col = styled.div`
  display: grid;
  gap:20px;
  width:100%;
  padding:0 10px;
  grid-template-columns: repeat(3,1fr);
  & > div{
    background-color: ${({theme})=> theme.background.primary};
    min-width:calc(33.1% - 10px);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding:10px;
    border-radius: 6px !important;
    box-shadow: var(--modal-shadow);
    cursor: pointer;
    border: 1px solid ${({theme})=> theme.color.dropBorder};
    & > div{
      display: flex;
      gap:20px;
      padding:5px 0;
      cursor:pointer;
      & > span{
        display: inline-block;
        width:80px;
        font-weight: 600;
        color:${props => props.theme.color.text};

      }
    }
    
    
  }
  @media screen and (width <= 1200px) {
    grid-template-columns: repeat(2,1fr);
  }
  @media screen and (width <= 600px) {
    grid-template-columns: 1fr;
  }
`