
import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import {BiUserCircle as UserImg} from 'react-icons/bi';
import {GrGroup as GroupImg} from 'react-icons/gr';
import {IoCalendarNumberSharp as EventImg} from 'react-icons/io5';
import { BsCalculatorFill as KPIIcon } from "react-icons/bs";
import { FaAddressCard as PrivilegeIcon } from "react-icons/fa";
import { SiYourtraveldottv as LeaveIcon,SiLevelsdotfyi as LevelIcon } from "react-icons/si";
import useApi from '../../hooks/useApi';
import { useState,useEffect } from 'react';
import {useSelector} from 'react-redux';


const Overview = () => {
  // const [cookies] = useCookies(['token','user']);
  const{ groups,privileges,users,levels,events} = useSelector(store=>store.hrState);

  const [filtered_events,set_filtered_events] = useState(events);


  useEffect(()=>{
    set_filtered_events(events);
  },[events]);
  const {cookies} = useApi();
  //console.log(cookies,'cookies')
  return (
    <Wrapper>
        <div>
          <h1>Welcome {cookies?.user?.first_name || cookies?.user?.name || 'username'}</h1>
          <span>User Management/Human Resources Management</span>
        </div>
        <div>
            <div>
              <div>
                <span>{cookies && cookies?.user?.org?.business_name}</span>
                <span>Human resources</span>
              </div>
              <div>
                <span>Motto</span>
                <span>{cookies && cookies?.user.org?.business_motto}</span>
              </div>
            </div>
            {/* <div>
              <span>Events</span>
              <div>
                <span>Employee of the month award</span>
                <span>24th of jun,2023</span>
              </div>
              <div>
                <span>Work dinner</span>
                <span>3rd of aug,2023</span>
              </div>
              <div>
                <span>Ngozi's birthday</span>
                <span>3rd of nov,2023</span>
              </div>
              <Link to={Event}>more...</Link>
            </div> */}
            <div style={{padding:'0 10px'}}>
              <span>Events</span>
              {filtered_events && filtered_events.length > 0 && !filtered_events.length > 3?
              filtered_events.map(eList =>  
                <div key={eList.id}>
                  <span>{eList.title}</span>
                  <span>{eList.date}</span>
                </div>
              ):'No Active Event'}
              {filtered_events.length > 0 && <Link to='/events'>more...</Link>}
            </div>
        </div>
        <div>
          <Link to="/level">
            <div>
              <span><LevelIcon /></span>
              <span>Levels</span>
            </div>
            <span>{levels.length}</span>
          </Link>
          <Link to="/users">
            <div>
              <span><UserImg/></span>
              <span>Users</span>
            </div>
            <span>{users.length}</span>
          </Link>
          <Link to="/groups">
            <div>
              <span><GroupImg style={{color:'#f1889a !important',}}/></span>
              <span>Groups</span>
            </div>
            <span>{groups.length}</span>
          </Link>
          <Link to="/events">
            <div>
              <span><EventImg/></span>              
              <span>Events/Calender</span>
            </div>
            <span>{events.length}</span>
          </Link>
          <Link to="/privileges">
            <div>
              <span><PrivilegeIcon/></span>              
              <span>Privileges</span>
            </div>
            <span>{privileges.length}</span>
          </Link>
         
        </div>
    </Wrapper>
  )
}

export default Overview
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height:100vh;
  & > div:first-of-type{
    display: flex;
    gap:10px;
    flex-direction:column;
    & >  h1{
    margin: 0;
    }
    & > span{
      font-family: Arial, Helvetica, sans-serif;
    }
  }
  & > div:nth-of-type(2){
    display: flex;
    flex-direction: row;
    gap: 30px;
    width:100%;
    margin-top: 30px;
    justify-content: space-between;
    & > div:first-of-type{
      display: flex;
      flex-direction: column;
      box-shadow: 6px 6px 13px 3px rgba(0,0,0,0.2);
      justify-content: space-between;
      width: 65%;
      border-radius: 6px;
      padding:10px;
      font-weight: 500;
      background-color: var(--primary-color);
      color:var(--white-color);
      font-size: 20px;
      height: 313px;
      cursor: pointer;
      & > div:first-of-type{
        display: flex;
        flex-direction: column;
        & > span:first-of-type{
          font-size: 30px;
        }
      }
      & > div{
        display: flex;
        flex-direction: column;
      }
      & > div:nth-of-type(2){
        font-size: 23px;
        & > span:nth-of-type(2){
          font-size: 17px;
        }
      }
    }
    & > div:nth-of-type(2){
      display: flex;
      flex-direction: column;
      box-shadow: 6px 6px 13px 3px rgba(0,0,0,0.2);
      width: 200px;
      flex-shrink: 0;
      border-radius: 6px;
      background-color: var(--white-color);     
      font-size: 14px;
      height: 313px;
      cursor:pointer; 
      &> span{
        font-size: 20px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 500;    
        padding:10px 15px 10px 15px;
      }
      & > div{
        border-top:1px solid #dbdbdb;
        display: flex;
        flex-direction: column;
        padding:10px 15px 10px 15px;
        gap:10px;
      }
      & > div:hover{
        background-color: var(--high-light-blue);
      }
      & > div:nth-of-type(4){
        border-bottom:1px solid #dbdbdb;
      }
      & > a{
        display: flex;
        justify-content: flex-end;
        text-decoration: none;
        padding:10px;
        color:unset;
      }
    } 
    @media screen and (width <= 450px){
      flex-direction: column;
      & > div:first-of-type,
      & > div:nth-of-type(2){
        width:100%;
        height:fit-content;
      }
      & > div:first-of-type{
        gap:30px;
      }
      & > div:nth-of-type(2){
        & > div{
          & > span:first-of-type{
            font-weight:500;
          }
        }
      }
    }
  }
  & > div:nth-of-type(3){
      display:flex;
      flex-direction:row;
      width:100%;
      gap:20px;
      margin-top: 50px;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap:20px;
      & > a{
        width:180px;
        height: 100px;
        box-shadow: 6px 6px 13px 3px rgba(0,0,0,0.2);
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px;
        padding: 15px;
        text-decoration: none;
       
        & > div{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          & > span:first-of-type{
            font-size: 50px;
            align-items: flex-start;
            display: flex;
          }
          & > span:nth-of-type(2){
            font-size: 18px;
            font-weight: 400;
          }
        }
        & > span{
            font-size: 24px;
        }

        &:first-of-type{
        background-color: #e0eaee;
        color:darkblue;
        }
        &:nth-of-type(2){
          background-color: #fadce0;
          color:#f83455;
          & svg > path{
            stroke:#f83455;
          }
        }
        &:nth-of-type(3){
          background-color: #c4e4c4;
          color:#045a04;
        }
        &:nth-of-type(4){
          background-color: #e0eaee;
          color:darkblue;
        }
        &:nth-of-type(5){
          background-color: lightgoldenrodyellow;
          color: #6d6d20;
        }
        &:nth-of-type(6){
          background-color: #fadce0;
          color:#f83455;
          & svg > path{
            stroke:#f83455;
          }
        }

        @media screen and (width <= 450px) {
          width:calc(50% - 10px);
          padding: 5px 10px;
          height: 70px;
          & > div{
          
          & > span:first-of-type{
            font-size: 20px;
            
          }
          & > span:nth-of-type(2){
            font-size: 14px;
           
          }
        }
        }
      }
     
    }
`
