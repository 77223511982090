import styled from '@emotion/styled';
import { useState,useEffect } from 'react';

const EditGroup = ({viewPrivilege = null}) => {
  const [title,setTitle] = useState('');
  const [description,setDescription] = useState('');


  useEffect(()=>{
    if(viewPrivilege){
      // setGroups(editGroup);
      setTitle(viewPrivilege.title);
      setDescription(viewPrivilege.description);
    }

  },[[viewPrivilege]])

  return (
    <Wrapper>
        <form>
           <h2>View Privilege</h2>

           {/* <i onClick={(e)=>showDeleteModal(e)}><Delete/></i> */}

           <label>
              <span>Title</span>
              <p>{title}</p>
           </label>

           <label>
              <span>Description</span>
              <p>{description}</p>
           </label>
        </form>
    </Wrapper>
  )
}

export default EditGroup

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* text-align: center; */
  position: relative;
  width: 600px;
  @media screen and (width <= 650px){
    width:96%;
  }
  padding: 0 10px 20px;

  & > form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:10px;
    position: relative;


    & > h2{
      text-align:center;
      // font-weight:700;
    }
    
    & > i{
      color: red;
      font-size: 25px;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
    }

    & > div:first-of-type{
      display:flex;
      align-items: flex-end;
      gap:20px;
      & > img{
        border: 1px solid #999;
        border-radius: 5px;
        width:100px;
        height:100px;
      }
    }
    & p{
      color:${({theme})=> theme.color.primary};
      font-size: 17px;
      font-weight: 600;
    }

    & > label{
      display: flex;
      flex-direction: column;
      gap:5px;
      font-size: 14px;
    }
    // & > div:not(:first-of-type){
    //     display: flex;
    //     gap:20px;
    //     @media screen and (width <=650px){
    //       flex-direction: column;
    //     }

    //     & > label{
    //       display: flex;
    //       flex-direction: column;
    //       gap:3px;  
    //       flex:1;

    //       & > span{
    //         font-size: 14px;
    //       }
    //     }
    //   }

    // & input:not([type="file"]), & select, & textarea{
    //   outline:none;
    //   border:1px solid ${({theme})=> theme.color.primary};
    //   background-color: transparent;
    //   width: 100%;
    //   padding: 8px 5px;
    //   border-radius: 4px;
    // }

    

    // & > button{
    //   background-color:var(--primary-color);
    //   padding: 10px 0;
    //   border: none;
    //   border-radius: 4px;
    //   color: var(--white-color);
    //   font-size: 17px;
    //   margin-top:10px;
    // }
  }

`

const ButtonFile =  styled.label`
  width:${props => props.width || 'fit-content'};
  overflow: hidden;
  position:relative;
  & > input{
    position: absolute;
    left:0;
    top:0;
    opacity:0;
  }
  & > button{
    cursor: pointer;
    background-color: ${props => props.bg || 'var(--primary-color)'};
    color:${props => props.color || 'var(--white-color)'};
    outline:none;
    display:flex;
    justify-content:center;
    align-items: center;
    gap: ${props => props.gap || '10px'};
    padding:10px 10px;
    width:100%;
    height: 100%;
    margin:0;
    border:1px solid ${props => props.border || 'var(--primary-color)'};
    border-radius: ${props => props.radius || '8px'};
  }
`