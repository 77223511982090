import styled from '@emotion/styled';
import React from 'react'
import { Button } from '../components';

const ConfirmModal = ({message = 'Confirm message',
ok=function(){},cancel=function(){}}) => {

  return (
    <Wrapper>
        <div>{message}</div>
        <div>
            <Button bg="var(--white-color)" color="var(--primary-color)" label="Cancel" onClick={cancel} border="1px solid var(--primary-color)" />
            <Button label="Ok" onClick={ok} />
        </div>
    </Wrapper>
  )
}

export default ConfirmModal;


const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap:50px;
    width:500px;
    & > div:first-of-type{
        line-height: 1.5;
        text-align: center;
        color:${({theme})=> theme.color.text};
    }
    & > div:nth-of-type(2){
        display:flex;
        justify-content: space-between;
    }

    @media screen and (width <= 550px){
        width:90%;
    }
    
`