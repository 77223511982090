import {useState,memo, useEffect}from 'react';
import { createContext } from 'react';
import { useCookies } from 'react-cookie';
// import { useLocation } from 'react-router-dom';



// const TEMP_BASE_URL = `http://localhost:5000`;
// const TEMP_LOGIN_PAGE = 'http://localhost:3000/login';


const TEMP_BASE_URL = `https://api.c-manager.xyz`;
const TEMP_LOGIN_PAGE = 'https://account.c-manager.xyz/login';

class ApiClass {
    constructor({cookies,base_url,login_page}){
        //console.log(cookies,base_url,'first')
        this.cookies = cookies;
        this.base_url = base_url
        this.LOGIN_PAGE = login_page;
    }
    isDomainUsed= false;

    check_network(){
        return window.navigator.onLine;
        //return true;
    }

    get_url = function (url){
        return this.isDomainUsed ? url : `${this.base_url}/${url}`;
    }
    
    get = function(url,shouldAddAuthorization=true){
        return new Promise((resolve,reject)=>{
            fetch(`${this.get_url(url)}`,{
                method:'GET',
                headers:shouldAddAuthorization?{
                    "Authorization":"Bearer "+ this.cookies.token,
                    "Content-Type":"application/json"
                }:{"Content-Type":"application/json"},
            }).then(res =>{
                if(res.status === 403){
                    window.location.href = this.LOGIN_PAGE;
                }
                if(res.ok && res.status > 199 && res.status < 300){
                    return(res.json());
                }
                reject(res);
            })
            .then(data => {
                if(data.token){
                    // set_cookies('token',data.token)
                    // set_cookies({...cookies,token:data.token});
                    this.cookies.token = data.token;
                }
                if(data.user){
                    // set_cookies('user',data.user)
                    // set_cookies({...cookies,user:data.user});
                    this.cookies.user = data.user;
                }
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
        })
    }

    post =  function({url,body={}},shouldAddAuthorization=true){
        //console.log(this.cookies.token,'token')
        return new Promise((resolve, reject) => {
            fetch(`${this.get_url(url)}`,{
                method:"POST",
                headers:shouldAddAuthorization?{
                    "Authorization":"Bearer "+ this.cookies.token,
                    "Content-Type":"application/json"
                }:{"Content-Type":"application/json"},
                body:JSON.stringify(body)
            })
            .then(res =>{
                //console.log(res,'api response')
                if(res.status === 403){
                    window.location.href = this.LOGIN_PAGE;
                }
                if(res.ok && res.status > 199 && res.status < 300){
                    return(res.json());
                }
                reject(res);
            })
            .then(data => {
                if(data.token){
                    // set_cookies('token',data.token)
                    //set_cookies({...cookies,token:data.token});
                    this.cookies.token = data.token;
                }
                if(data.user){
                    // set_cookies('user',data.user)
                    //set_cookies({...cookies,user:data.user});
                    this.cookies.user = data.user;
                }
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
        }) 
    }

    form_post = function({url,body},shouldAddAuthorization=true){
        return new Promise((resolve,reject)=>{
            let xhhtp = new XMLHttpRequest();
            xhhtp.onreadystatechange = function(){   
                //console.log(`state: ${this.readyState} | status: ${this.status}`)   
                if(this.status === 403){
                    window.location.href = this.LOGIN_PAGE;
                }            
                if (this.readyState == 4 && this.status > 199 &&this.status <= 299){
                    console.log(xhhtp,'response data')
                    console.log(JSON.parse(xhhtp.responseText),'response data')
                    let res = JSON.parse(xhhtp.responseText)
                    // console.log(res)  
                    resolve(res)                          
                }
                else if(this.readyState == 4 && this.status >= 300){
                    reject({
                        isSuccessful: false
                    })
                }
            }
            xhhtp.open("POST", this.get_url(url), true); 
            if(shouldAddAuthorization){
                xhhtp.setRequestHeader("Authorization", "Bearer " + this.cookies.token);
            }
            xhhtp.send(body);
        })
    }
    
  }

export const ApiContext = createContext({});

const ApiContextProvider = ({children}) => {
    const [base_url,set_base_url] = useState(TEMP_BASE_URL);
    const [login_page,set_login_page] = useState(TEMP_LOGIN_PAGE);
    const [cookies,handle_cookies] = useState({user:null,token:null});
    const [fetch_status, set_fetch_status] = useState(false);
    const [param,set_param] = useState('');
    const [date,set_date] = useState(new Date());
    
  
    const [api,set_api] = useState(new ApiClass({cookies,base_url,login_page}));

    const set_cookies = (cook)=>{
        let temp_cook = JSON.parse(localStorage.getItem('cmag-new-cookies') || 'null');
        if(!temp_cook) temp_cook = cook;
        handle_cookies({...cookies,...cook});
        localStorage.setItem('cmag-new-cookies',JSON.stringify({...temp_cook,...cook}))
    }     

    useEffect(()=>{
        set_base_url(TEMP_BASE_URL);
        set_login_page(TEMP_LOGIN_PAGE);
        set_api(new ApiClass({cookies,base_url,login_page}));
        set_fetch_status(true);
    
        let temp_cookies = JSON.parse(localStorage.getItem('cmag-new-cookies') || 'null');
        if(temp_cookies){
            set_cookies(temp_cookies);
        }
        let temp_param = localStorage.getItem('cmag-param');
        if(temp_param)set_param(temp_param);
    },[])

    useEffect(()=>{
        //console.log(cookies,'new cookies');
        set_api(new ApiClass({cookies,base_url,login_page}));
    },[cookies])

    const value ={
        Api:api,
        BASE_URL:base_url,
        LOGIN_PAGE:login_page,
        cookies,
        set_cookies,
        param,
        set_param,
        date,
        set_date,
        fetch_status,
        set_fetch_status,
    }
    
    return (
        <ApiContext.Provider value={value}>
        {children}
        </ApiContext.Provider>
    )
}

export default memo(ApiContextProvider);