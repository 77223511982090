import {memo,useEffect,useRef, useState} from 'react'; 
import {Button} from '../';
import {HiOutlineAdjustmentsVertical as FilterIcon} from 'react-icons/hi2';
import styled from '@emotion/styled';


const Filter = ({list = [],onChange,label='value', bg='var(--primary-dark-color)',color='var(--primary-color)'}) => {
  const divRef = useRef(null);
  const spanRef = useRef(null);
  const [show,set_show] = useState(false);
  const [active_index,set_active_index] = useState(0);
  const tempList = [...list];
  const tempOnChange = onChange || function(arg){};

  const selectFilter = (index)=>{
     tempOnChange(index);
     set_active_index(index);

  }

  const handleClose =() =>{
    set_show(false);
  }

  useEffect(()=>{
    const handleOutsideClick = (e)=>{
      if(spanRef && spanRef.current && divRef && divRef.current){
          const spanDimensions = spanRef.current.getBoundingClientRect();
          const divDimensions = divRef.current.getBoundingClientRect();
          if( e.clientX < divDimensions.left ||
              e.clientX > spanDimensions.right ||
              e.clientY < spanDimensions.top ||
              e.clientY > divDimensions.bottom){
                  handleClose();
          }   
      }
    }
    window.addEventListener('click',handleOutsideClick);

    return ()=> window.removeEventListener('click',handleOutsideClick);
  },[])  
  return (
    <Wrapper ref={spanRef}>
        <Button {...{bg,color}} onClick={()=>set_show(show => !show)}>
          <StyledSpan><FilterIcon/></StyledSpan>
          <span>Filter</span>
        </Button>
        {show ? <StyledDiv ref={divRef}>
          {tempList.length > 0 ? tempList.map((eList,index) => <div key={index} onClick={()=>selectFilter(index)} className={index == active_index?'active':''}>
            {typeof eList === 'string'?eList: eList[label] }
          </div>):''}
        </StyledDiv>:''}
        
    </Wrapper>
    
  )
}

const StyledSpan = styled.span`
  font-size:17px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledDiv = styled.div`
  position: absolute;
  display:flex;
  flex-direction: column;
  width:200px;
  top:38px;
  background-color: ${({theme})=> theme.background.primary};
  padding:10px 0;
  border-radius: 6px;
  box-shadow: var(--modal-shadow);
  & > div{
    padding:10px;
    cursor: pointer;
    &:hover{
      background-color: var(--light-blue);
      color:var(--white-color);
    }
    &.active{
      background-color: var(--light-blue);
      color:var(--white-color);
    }
  }
`

const Wrapper = styled.span`
  display:inline-block;
  position: relative;
  
`

export default memo(Filter);