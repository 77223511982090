import style from "../css/loading.module.css";

const Loading = () =>{
    return(
        <>
            <div className={style.parent} style={{display:'flex'}}>
                <div className={style.loader}></div>
            </div>
        </>
    )
}

export default Loading;