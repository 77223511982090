import styled from '@emotion/styled';
import { useState,useRef,memo } from 'react';
import { Button } from '../components';
import useModal from '../hooks/useModal';
import useServices from '../hooks/useServices';
import { AddBusiness } from '.';

const TOTAL_VIEW = 3;

const messages = {
    0:{
        header:`Manage Your Business(es) more effectively
        `,
        body:`Register & manage efficiently one or more businesses in c-manager. Add users and
        assign privileges to user groups to control platform access  
        `,
        image:'./register-business.png',
    },
    1:{
        header:'All your Business management tools in one Place',
        body:`
            C-Manager have all tools you need to manage and grow your business more efficiently and at a lower cost. No need for many tasking software integrations
            
        `,
        image:'./services.png'
    },
    2:{
        header:'Powerful Reporting',
        body:`
            Gain powerful insight into your business growth, employee engagement, financial reports
            and many more.
        `,
        image:'./dashboard.png'
    }
}
let x = 0;
const OnBoarding = () => {
    const [view,set_view] = useState(0);
    const highLightRef = useRef(null);
    const {showServices} = useServices();
    const {showModal} = useModal();
    const slideRef = useRef(null);
    const aniObject = {
            0:"0px",
            1:"18px",
            2:"36px",
            3:"54px"
        }
    
    const handleGetStarted = () =>{
        showServices({close:true});
        showModal({content:<AddBusiness />,cancel:false,dark:true});
    }

    const handleChangeView = ({previous=false}) =>{
        if(previous){
            if(view > 0){
                set_view(view-1)
                // console.log(view-1,'view');
                x = x+100;
            }
        }
        else{
            if(view < TOTAL_VIEW-1){
                set_view(view+1);
                // console.log(view+1,'view');
                x= x-100;

            }
            else if(view === TOTAL_VIEW-1){
                handleGetStarted();
            }

        }
        if(slideRef && slideRef.current){
            //console.log(x,'x value')
            slideRef.current.style.transform = `translateX(${x}%)`;
        
        }
        
    }

  return (
    <Wrapper>
        <div>
            <h2>{messages[view].header}</h2>
            <div ref={slideRef}>
                <div>
                    <div><img src={messages[0].image} alt="image" /></div>
                    <div>{messages[0].body} </div>
                </div>
                <div>
                    <div><img src={messages[1].image} alt="image" /></div>
                    <div>{messages[1].body} </div>
                </div>
                <div>
                <div><img src={messages[2].image} alt="image" /></div>
                    <div>{messages[2].body} </div>
                </div>
                
                               
            </div>
        </div>
        <div>
            {<Button label="Previous" style={{backgroundColor:view === 0?'transparent':'var(--primary-color)',color:view === 0?'transparent':'var(--white-color)'}} onClick={()=>handleChangeView({previous:true})} /> }
            <div>
                <i ref={highLightRef} style={{left:aniObject[view]}}></i>
                <span className='active'></span>
                <span></span>
                <span></span>
                {/* <span></span> */}
            </div>
            <Button onClick={()=>handleChangeView({previous:false})} label={view === TOTAL_VIEW-1?'Get started':'Next'} />
        </div>
    </Wrapper>
  )
}

export default memo(OnBoarding);


const Wrapper = styled.div`
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div:first-of-type{
        background-color: var(--primary-color);
        width:100%;
        height:calc(100% - 70px);
        @media screen and (width <= 767px) {
            height: calc(100% - 50px);
        }
        & > h2{
            text-align: center;
            color:var(--white-color);
        }
        & > div{

            display: flex;
            height:calc(100% - 52px);
            transition: all 0.4s;
            transform: translateX(0) ;
            & > div{
                
                display: flex;
                width:100%;
                height:100%;
                flex-shrink: 0;
                
                padding:20px;
                gap:20px;
                justify-content: center;
                & > div:first-of-type{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width:calc(60% - 20px);
                    flex-wrap: wrap;
                    height:100%;
                    & > img{
                        width:80%;
                        height:90%;
                        object-fit: contain;
                        object-position:center;
                        position: relative;
                    }
                }
                & > div:nth-of-type(2){
                    color:var(--white-color);
                    font-size:2.1dvw;
                    font-weight:500;
                    width:40%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items:center;
                }

            }
           
        }
    }
    & > div:nth-of-type(2){
        width:100%;
        height:70px;
        position: relative;
        z-index: 1;
        @media screen and (width <= 767px) {
            height:50px;
        }
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        & > div{
            display: flex;
            gap:8px;
            width:fit-content;
            position: relative;
            &> i{
                position: absolute;
                display: inline-block;
                width:11px;
                height:11px;
                border-radius: 50%;
                background-color: var(--primary-color);
                left:0;
                top:0;
                transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
            & > span{
                display:inline-block;
                width:10px;
                height:10px;
                border-radius: 50%;
                background-color:var(--white-color) ;
                
            }
        }
    }

    @media screen and (width <= 767px) {
        & > div:first-of-type > h2{
            padding:20px;
            margin:0;
        }
        & > div:first-of-type > div > div{
            flex-direction: column !important;
            height:fit-content;
        }
        & > div:first-of-type > div > div > div:first-of-type{
            height:200px;
            width:100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
        & > div:first-of-type > div > div > div:nth-of-type(2){
            width:100%;
            font-size:18px;
        } 
        
    }

    
`