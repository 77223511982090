import styled from '@emotion/styled';
import {memo} from 'react';
import {Button} from '../';
import { FaSearch as SearchIcon } from 'react-icons/fa';
import { useState } from 'react';


const Search = ({placeholder = 'search',onChange}) => {
  const [value,set_value] = useState('');

  const handleForm = (e) =>{
    e.preventDefault();

  }
  
  const handleSearch = (e)=>{
    const value = e.target.value;
    onChange(value);
    set_value(value);
  }

  return (
    <StyledForm onSubmit={handleForm}>
        <input placeholder={placeholder} value={value} onChange={handleSearch}/><Button label={<SearchIcon />} />
    </StyledForm>
  )
}

export default memo(Search);


const StyledForm = styled.form`
    display: flex;
    border-radius: 6px;
    width: min(400px,40%);
    min-width:250px;
    position:relative;
    height: 40px;
    border:1px solid var(--primary-color);
    & > input{
        width:100%;
        border:none;
        border-radius: 6px;
        padding: 11px 80px 11px 10px;
        outline:none;
    }
    & > button{
        /* display:none; */
        position:absolute;
        right:0;
        top:0;
        height: 100%;
        border-radius: 0 5px 5px 0;
        padding-right:15px;
        padding-left: 15px;
    }
    
`