import styled from '@emotion/styled';
import useModal from '../../hooks/useModal'
import { useState,useEffect } from 'react';
import { ConfirmModal } from '../../modal';
import {RiDeleteBin6Line as Delete} from 'react-icons/ri'
import { useCookies } from 'react-cookie';
import useNotification from '../../hooks/useNotification';
import useApi from '../../hooks/useApi';
import useLoading from '../../hooks/useLoading';
import {addGroup,editGroup as editGroupDetail} from '../../redux/reducers/hrReducer';
import { useDispatch,useSelector } from 'react-redux';
import { Select } from '../../components';
// import {deleteGroup} from '../../redux/reducers/hrReducer';

const EditGroup = ({editGroup = null}) => {
  const {showModal} = useModal();
  const allPrivileges = useSelector(store => store.hrState.privileges);
  const [title,setTitle] = useState('');
  const [privileges,set_privileges] = useState('');
  const [description,setDescription] = useState('');
  const [users_num,setUsers_num] = useState(0);
  const {showNotification} = useNotification();
  const {loading} = useLoading();
  const dispatch = useDispatch();
  const {Api,cookies} = useApi();

  // const showDeleteModal = ()=>{
  //   showModal({content:<ConfirmModal message="Are you sure you want to delete this group" ok={handleDeleteGroup} cancel={closeDeleteModal} />});
  // }
  // const closeDeleteModal = ()=>{
  //   showModal({close:true});
  // }
  // const handleDeleteGroup = ()=>{
  //   if(Api.check_network()){
  //     let url = `hr/delete-group`;
  //     let body = {
  //       id:editGroup.id,
  //       // business_id:cookies.user.org.id
  //     };
  //     loading();
  //     try{
  //       Api.post({url,body})
  //         .then(data => {
  //           loading(true);
  //           if(data.isSuccessful){
  //             dispatch(deleteGroup({id:editGroup.id}))
  //             showNotification({
  //               type:'success',
  //               message:data.message || `Group Deleted Successful`
  //             })
  //           }
  //           else{
  //             showNotification({
  //               type:'error',
  //               message:data.message || `Sorry, action could not be carried out`
  //             })
  //           }
  //       })
  //     }
  //     catch(err){
  //       loading(true);
  //       showNotification({
  //         type:'error',
  //         message:err.message || `Error ocurred try again`
  //       })

  //     }
  //     finally{
  //       showModal({close:true});
  //     }
      
  //   }
  // }

  useEffect(()=>{
    if(editGroup){
      setTitle(editGroup.title);
      setDescription(editGroup.description);
      setUsers_num(editGroup.users_num || 0);
      set_privileges(editGroup.privileges);
    }
    else{
      clearForm()
    }

  },[editGroup])

  const clearForm = () =>{
    setTitle('');
    setDescription('');
    setUsers_num(0);
    set_privileges([]);
  }

  const handleAddGroup = (e) =>{
    e.preventDefault();
    if(title && description && privileges && privileges.length > 0){
      const body = {
        title: title,
        description: description,
        users_num: users_num,
        // business_id: cookies.user.org.id,
        privilege_list:privileges.map(e => e.id)
      }
      if(editGroup)body.id = editGroup.id;
      if(Api.check_network()){
        const url = editGroup?'hr/edit-group':'hr/create-new-group';
        loading()
        Api.post({url,body})
        .then(data => {
          loading(true)
          if(data.isSuccessful){
            editGroup?dispatch(editGroupDetail({
              id:editGroup.id,
              title,
              description,
              users_num:editGroup.users_num,
              privileges,
            })):dispatch(addGroup({
              id:data.data.id,
              title,
              description,
              users_num,
              privileges,
            }));
            if(editGroup)showModal({close:true})      
            clearForm();
            
            showNotification({
              type:'success',
              message:data.message || editGroup?'Group Edited succesfully':'Group Create Successfully'
            })
          }
          else{
            showNotification({
              type:'error',
              message:data.message || "Error occured try again later"
            })
          }
        })
        .catch(err =>{
          loading(true)
          showNotification({
            type:'error',
            message:err.message || "An Error occured"
          })
        })
      }
      else{
        showNotification({
          type:'error',
          message:"No Internet Connectivity"
        })
      }
    }
    else{
      showNotification({
        type:'error',
        message:"Fill All required fields"
      })
    }

  }

  return (
    <Wrapper>
      <form onSubmit={handleAddGroup}>
        <h2>{editGroup?'Edit Group':'Create Group'}</h2>

        {/* <i onClick={(e)=>showDeleteModal(e)}>{editGroup?<Delete/>:''}</i> */}

        <label>
          <span>Title*</span>
          <input value={title} onChange={(e)=>setTitle(e.target.value)} type="text" placeholder="Enter Title"/>
        </label>

        <label>
          <span>Description*</span>
          <textarea value={description} onChange={(e)=>setDescription(e.target.value)} placeholder="Enter Description" cols="30" rows="10"></textarea>
        </label>

        <label>
          <span>Number of Users</span>
          <input disabled value={users_num} onChange={(e)=>setUsers_num(e.target.value)} type="text" placeholder="Enter Number of Users"/>
        </label>
        <label>
          <span>Privileges</span>
          <Select options={allPrivileges} defaultValue={privileges} multi onChange={set_privileges} placeholder="Select Privileges" />
        </label>
        <button>{editGroup?'Edit Group':'Submit'}</button>
      </form>
    </Wrapper>
  )
}

export default EditGroup

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* text-align: center; */
    position: relative;
    width: 600px;
    @media screen and (width <= 650px){
      width:96%;
    }
    padding: 0 10px 20px;

    & > form{
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap:10px;
      position: relative;


      & > h2{
        text-align:center;
      }
      
      & > i{
        color: red;
        font-size: 25px;
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
      }

      & > div:first-of-type{
        display:flex;
        align-items: flex-end;
        gap:20px;
        & > img{
          border: 1px solid #999;
          border-radius: 5px;
          width:100px;
          height:100px;
        }
      }

      & > label{
        display: flex;
        flex-direction: column;
        gap:5px;
        font-size: 14px;

      }
      // & > div:not(:first-of-type){
      //     display: flex;
      //     gap:20px;
      //     @media screen and (width <=650px){
      //       flex-direction: column;
      //     }

      //     & > label{
      //       display: flex;
      //       flex-direction: column;
      //       gap:3px;  
      //       flex:1;

      //       & > span{
      //         font-size: 14px;
      //       }
      //     }
      //   }

      & > label input:not([type="file"]), & > label > select, & > label > textarea{
        outline:none;
        border:1px solid ${({theme})=> theme.color.primary};
        background-color: transparent;
        width: 100%;
        padding: 8px 5px;
        border-radius: 4px;
      }

      

      & > button{
        background-color:var(--primary-color);
        padding: 10px 0;
        border: none;
        border-radius: 4px;
        color: var(--white-color);
        font-size: 17px;
        margin-top:10px;
      }
    }
    & > div{
      position: absolute;
      top: 0px ;
      display: none;
      align-items: center;
      left: 0px;
      // right: 0px;
      height: 100%;
      width: 100%;
      background-color:${({theme})=> theme.background.backdrop};
      // box-sizing: content-box;
      box-shadow: var(--modal-shadow);
      // margin-right: -10px;
      // margin-right: -20px;

      & > div{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap:10px;
        height: 130px;
        width: 100%;
        background-color: ${({theme})=> theme.background.primary};
  
        & > span{
          font-size: 17px;
          font-weight: 700;
        }
  
        & > div{
          display: flex;
          gap: 30px;
  
          & > button{
              display: flex;
              outline: none;
              border: none;
              border-radius: 5px;
              height: 35px;
              width: 50px;
              align-items: center;
              justify-content: center;
              background-color: ${({theme})=> theme.color.primary};
              color: ${({theme})=> theme.background.primary};
              cursor: pointer
          }
        }
      }
    }

`

const ButtonFile =  styled.label`
  width:${props => props.width || 'fit-content'};
  overflow: hidden;
  position:relative;
  & > input{
    position: absolute;
    left:0;
    top:0;
    opacity:0;
  }
  & > button{
    cursor: pointer;
    background-color: ${props => props.bg || 'var(--primary-color)'};
    color:${props => props.color || 'var(--white-color)'};
    outline:none;
    display:flex;
    justify-content:center;
    align-items: center;
    gap: ${props => props.gap || '10px'};
    padding:10px 10px;
    width:100%;
    height: 100%;
    margin:0;
    border:1px solid ${props => props.border || 'var(--primary-color)'};
    border-radius: ${props => props.radius || '8px'};
  }
`