import { createSlice } from "@reduxjs/toolkit";

export const kpiSlice = createSlice({
    name: 'kpiState',
    initialState:{
        kpi_settings:[],
        kpi_groups:[],
        kpi:[],
        kpi_score:[],
        kpi_score_settings:[]
    },
    reducers:{
        setKpiSettings: (state,action)=>{
            return {
                ...state,kpi_settings:action.payload
            }   
        },
        addKpiSettings:(state,action)=>{
            return {
                ...state,kpi_settings:[
                    action.payload,
                    ...state.kpi_settings
                ]
            }   
        },
        editKpiGroup:(state,action)=>{
            let tempUsers = state.users.map(eUser => eUser.id != action.payload.id?action.payload:eUser);
            return{
                ...state,users:tempUsers
            }
            
        },
        addKpiGroup:(state,action)=>{
            return {
                ...state,kpi_settings:[
                    action.payload,
                    ...state.kpi_settings
                ]
            }   
        },
      
    }
})

export const {setKpiSettings,addKpiSettings, editKpiGroup, addKpiGroup} = kpiSlice.actions;
export default kpiSlice.reducer;