import { memo } from 'react';
import styled from '@emotion/styled';


const Leave = () => {
  
  return (
    <Wrapper> 
      Leave   
    </Wrapper>
  )
}

export default memo(Leave);

const Wrapper = styled.div`
  
`

// const Wrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     height: auto;
//     & > div:first-of-type{
//         display: flex;
//         align-items: center;
//         gap: 20px;
//         width: 100%;
//         @media screen and (width <= 800px) {
//         flex-wrap: wrap;
//         gap:10px;
//         }
//         & > button:last-of-type{
//             margin-left:auto;
//             @media screen and (width <= 800px) {
//                 margin-left: 0;
//             }
//         }
//     }
// `

// const Container = styled.div`
//   width:100%;
//   overflow: ${props => props.display == 0?'scroll auto':'hidden'} ;
  
//   background-color: ${(props) => props.display == 0?props.theme.background.primary:''};
//   box-shadow: ${props => props.display == 0?'var(--modal-shadow)':''};
//   margin-top:10px;
//   border-radius: 5px 5px 0 0;
//   display: flex;
//   padding:${({display})=> display == 0 ?'':'20px 0px'}
// `


// const Row= styled.div`
//   flex:1;
//   & > div:first-of-type{
//     display: grid;
//     grid-template-columns: repeat(1,1fr) 1.5fr repeat(3,0.9fr);
//     background-color: ${({theme})=> theme.background.header };
//     gap:10px;
//     width:100%;
//     padding:15px 10px;
//     border-radius: 5px;
//     & > span{
//       font-weight: 500;
//       min-width: 100px;
//       &:nth-of-type(3){
//         min-width:200px;
//       }
//     }
//   }
//   & > div:nth-of-type(2){
//     margin-top: 5px;
//     display:flex;
//     width: 100%;
//     flex-direction: column;
//     padding-bottom: 30px;
//     gap:5px;
//     & > div{
//       display: grid;
//       grid-template-columns: repeat(1,1fr) 1.5fr repeat(3,0.9fr);
//       background-color:${({theme})=> theme.background.primary };
//       border-radius: 3px;
//       border:1px solid ${({theme})=> theme.color.border };
//       padding:10px;
//       gap:10px;
//       width:100%;
//       border-radius: 3px;
//       & > div{
//         min-width: 100px;
//         overflow:hidden;
//         word-break: keep-all;
//       }
//       & > div:nth-of-type(3){
//         /* min-width:200px; */
//         flex:1;
//       }
//     }

//   }
  
// `

// const Col = styled.div`
//   display: grid;
//   gap:20px;
//   width:100%;
//   padding:0 10px;
//   grid-template-columns: repeat(3,1fr);
//   & > div{
//     background-color: ${({theme})=> theme.background.primary};
//     min-width:calc(33.1% - 10px);
//     flex-shrink: 0;
//     display: flex;
//     flex-direction: column;
//     padding:10px;
//     border-radius: 6px !important;
//     box-shadow: var(--modal-shadow);
//     border: 1px solid ${({theme})=> theme.color.dropBorder};
//     & > div{
//       display: flex;
//       gap:20px;
//       padding:5px 0;
//       cursor:pointer;
//       & > span{
//         display: inline-block;
//         width:80px;
//         font-weight: 600;
//         color:${props => props.theme.color.text};

//       }
//     }
    
    
//   }
//   @media screen and (width <= 1200px) {
//     grid-template-columns: repeat(2,1fr);
//   }
//   @media screen and (width <= 600px) {
//     grid-template-columns: 1fr;
//   }
// `