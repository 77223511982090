import React from 'react'
import {Routes,Route} from 'react-router-dom';
import styled from '@emotion/styled';
import {Overview,Users,Groups,Events,Privileges,
  Kpi,Leave,Level,AllKpi,KpiGroups,KpiScore,KpiSettings,
  KpiScoreSettings,KpiRoutes} from '../views';


const Main = () => {
  return (
    <Wrapper>
      <Routes>
        <Route index element={<Overview />}/>
        <Route path="/overview/*" element={<Overview />} />
        <Route path="/users/*" element={<Users />} />
        <Route path="/groups/*" element={<Groups/>} />
        <Route path="/events/*" element={<Events/>} />
        <Route path="/privileges/*" element={<Privileges/>} />
        <Route path="/leave-management" element={<Leave />} />
        <Route path="/level/*" element={<Level />} />
        <Route path="/kpi/*" element={<KpiRoutes />}>
          <Route index element={<Kpi />} />
          <Route path="kpi-settings" element={<KpiSettings />}/>
          <Route path="kpi-groups" element={<KpiGroups />} />
          <Route path="all-kpi" element={<Kpi />} />
          <Route path="kpi-score" element={<KpiScore />} />
          <Route path='kpi-score-settings' element={<KpiScoreSettings />} />
        </Route>

      </Routes>

    </Wrapper>
  )
}

export default Main;

const Wrapper = styled.section`
  padding:20px;
  flex:1;
  background-color: ${({theme})=> theme.background.secondary};
  color:${({theme})=> theme.color.text};
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 40px;

  @media screen and (width <= 800px) {
     padding:10px 7px;
  }
`