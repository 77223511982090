import {useState,memo,useRef, useEffect} from 'react';
import styled from '@emotion/styled';
import { FaChevronDown as DownIcon, FaChevronUp as UpIcon } from 'react-icons/fa';


const Single = (props) => {
    const options = props.options;
    const label = props.label || 'name';
    const value = props.value || 'id';
    const defaultValue = props.defaultValue;
    const temp_placeholder = props.placeholder || 'Select Option';
    const onChange = props.onChange || function(){};
    const reset = props.reset;
    const disabled = props.disabled || false;
    const name = Math.floor(Math.random()* 1000000) +1;
    const [input_value,set_input_value] = useState('');  
    const [filter_options,set_filter_options] = useState(options)
    const [selected,set_selected] = useState(null);
    const [show,set_show] = useState(false);
    const [top,set_top] = useState('');   
    const divRef = useRef(null);
    const divRef2 = useRef(null);
   
    const handleClick =(e) =>{
        if(!disabled){
            set_show(!show)  
        }
    }
    const handleSelect = (selected) =>{
        onChange(selected);
        set_selected(selected);
        if(selected && selected[label]){
            set_input_value(selected[label]);
            return;
        }
        else{
            set_input_value('');
        }
        
    }

    const handleInput = (e) =>{
        const val = e.target.value;
        set_input_value(val);
        set_show(true);
        const filter = options.filter(eOption => eOption[label].toLowerCase().includes(val.toLowerCase()));
        if(filter.length > 0) set_filter_options(filter);
        else set_filter_options(options);
    }
    const handleClose=()=> set_show(false);

    useEffect(()=>{
        if(defaultValue && defaultValue[value] && defaultValue[label]){
            handleSelect(defaultValue);
        }
        else if(defaultValue){
            let temp = options.find(eOption => eOption[value] == defaultValue);
            if (temp){ handleSelect(temp); return}
            handleSelect(null);
        }
        else{
            handleSelect(null);
        }

    },[defaultValue]);

    useEffect(()=>{
        handleSelect(null);
    },[reset])

    useEffect(()=>{
        const handleOutsideClick = (e)=>{
            if(divRef && divRef.current && e && divRef2 && divRef2.current){
                const divDimensions = divRef.current.getBoundingClientRect();
                const divDimensions2 = divRef2.current.getBoundingClientRect();
                if( (e.clientX < divDimensions.left ||
                    e.clientX > divDimensions.right ||
                    e.clientY < divDimensions.top ||
                    e.clientY > divDimensions.bottom) && (e.clientX < divDimensions2.left ||
                        e.clientX > divDimensions2.right ||
                        e.clientY < divDimensions2.top ||
                        e.clientY > divDimensions2.bottom) ){
                    handleClose();
                }
                if((e.clientY > window.screen.availHeight/2) && !show){
                    set_top('1');
                }
                else if(show){
                    set_top('');
                }
            }
        }
        window.addEventListener('click',handleOutsideClick);
        return ()=> {window.removeEventListener('click',handleOutsideClick)}
    },[])

  return (
    <Wrapper ref={divRef} top={top} style={{backgroundColor:disabled?'#eee':''}}>
        <div >
            <label onClick={handleClick} >
                <input disabled={disabled} value={input_value} placeholder={temp_placeholder} onChange={handleInput} />
            </label>
            <span onClick={handleClick}>{!show?<DownIcon/>:<UpIcon />}</span>
        </div>
        {show ? <div ref={divRef2} >
            {filter_options && filter_options.length > 0 ? filter_options.map((eOption =>
                <label key={eOption[value]} onClick={()=>handleSelect(eOption)} >  
                    <input type="radio" name={'select'+name} checked={selected?selected[value] == eOption[value]:false} onChange={(e)=>{}} />
                    <span>{eOption[label]}</span>
                </label>
            
            )):''}
        </div>:''}
    </Wrapper>
  )
}

export default memo(Single);


const Wrapper = styled.div`
    width:100%;
    height:40px;
    border:1px solid var(--primary-color);
    border-radius: 3px;
    padding:3px;
    position:relative;
    z-index: 2;
    & > div{
        &:first-of-type{
            width:100%;
            display:flex;
            align-items: center;
            position: relative;
            height:100%;
            label{
                width:100%;
                position: relative;
                height:100%;
                /* z-index: 1;*/
                & > input{
                    width:100%;
                    height:100%;
                    border:none;
                    outline:none;
                    background-color: transparent;
                }
            }
            & > span{
                position:absolute;
                right:0;
                border-left:1px solid var(--primary-color);
                display: flex;
                justify-content: center;
                align-items: center;
                height:32px;
                width:30px;
                cursor: pointer;
            }
        }
        &:nth-of-type(2){
            border:1px solid #999;
            border-radius: 3px;
            max-height: 300px;
            position: absolute;
            top:${props => props.top? 'unset':'40px'};
            bottom:${props => props.top?'40px':'unset'};
            left:0;
            width:100%;
            z-index: 1;
            background-color: #eee;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 1px;
            }
            display: flex;
            flex-direction: column;
            & > label{
                padding:8px 0;
                display: flex;
                gap:10px;
                cursor: pointer;
                & > input{
                    width:20px;
                }
                &:hover{
                    background-color: #ddd;
                }
                &:has( > input:checked){
                    background-color: #ddd;
                }
            }
        }
    }
`;