import styled from '@emotion/styled';
import {memo,useState} from 'react';
import {MdTableRows as Row} from 'react-icons/md';
import {IoGrid as Grid} from 'react-icons/io5';

const Toggle = ({onChange =function(){}}) => {
    const [selected,set_selected] = useState(0);
    const handleDisplay = (index)=>{
        onChange(index);
        set_selected(index);
    }
  return (
    <Wrapper>
        <span onClick={()=>handleDisplay(0)} className={selected == 0?'active':''}>
            <Row />
        </span>
        <span onClick={()=>handleDisplay(1)} className={selected == 1?'active':''}>
            <Grid />
        </span>
    </Wrapper>
  )
}

export default memo(Toggle);


const Wrapper = styled.span`
    display: flex;
    align-items: center;
    gap:5px;

    & > span{
        cursor:pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:20px;
        padding:5px;
        border-radius: 3px;
        color:var(--primary-color);
        &.active{
            background-color: var(--primary-dark-color);
        }
    }    
`