import {useState,memo, useEffect} from 'react'
import styled from '@emotion/styled';
import {BusProfile} from '.';
import { Link,useLocation } from 'react-router-dom';
import {TbView360 as OverviewIcon} from 'react-icons/tb';
import {GoTriangleUp as ShowIcon} from 'react-icons/go';
import {FaUsers as UserIcon} from 'react-icons/fa';
import {TiGroup as GroupIcon} from 'react-icons/ti';
import {IoCalendarNumberSharp as EventIcon} from 'react-icons/io5';
import { BsCalculatorFill as KPIIcon } from "react-icons/bs";
import { FaAddressCard as PrivilegeIcon } from "react-icons/fa";
import { SiYourtraveldottv as LeaveIcon,SiLevelsdotfyi as LevelIcon } from "react-icons/si";
import { FaChevronDown as DownIcon,FaChevronUp as UpIcon } from "react-icons/fa";

import { GoDash as DotIcon } from "react-icons/go";




const list= [
  { id:-1,
    name:"Overview",
    path:'/',
    icon:<OverviewIcon />
  },
  {
    id:1,
    name:'Level',
    path:'level',
    icon:<LevelIcon />
  },
  {
    id:2,
    name:"Groups",
    path:'groups',
    icon:<GroupIcon/>,
  },
  {
    id:3,
    name:"Users",
    path:'users',
    icon:<UserIcon />,
  },
  {
    id:4,
    name:'Leave Management',
    path:'leave-management',
    icon:<LeaveIcon />,
  },
  {
    id:5,
    name:"KPI",
    path:'kpi',
    icon:<KPIIcon />,
    sub_list:[
      {
        id:3,
        name:'All Kpi',
        path:'all-kpi',
      },
      {
        id:1,
        name:'Kpi Settings',
        path:'kpi-settings',
      },
      {
        id:2,
        name:'Kpi Group',
        path:'kpi-groups',
      },
      {
        id:4,
        name:'Kpi Score',
        path:'kpi-score',
      },
      {
        id:5,
        name:'Kpi Score Setting',
        path:'kpi-score-settings',
      },
      
    ]
  },
 
  {
    id:6,
    name:"Events/Calendar",
    path:'events',
    icon:<EventIcon/>
  },
  {
    id:7,
    name:"Privileges",
    path:'privileges',
    icon:<PrivilegeIcon/>
  },
 
  
  
  
]

const MAXIMUM = 6;

const Sidenav = () => {
  const [minimize,set_minimize] = useState(false);
  const [show_more,set_show_more] = useState(false);

  const handleMinimize=()=>{
    set_minimize(!minimize);
  }
  
  return (
    <Wrapper minimize={minimize?'1':''}>
      <BusProfile onClick={()=>handleMinimize()} minimize={minimize} />
      <div >
        {list.slice(0,MAXIMUM).map((eLink)=>
           <EachLink key={eLink.id} {...{...eLink,set_show_more}} minimize={minimize} />
        )}
       {list && list.length > MAXIMUM &&  <section>
          <span title="see more" className={show_more?'show':''} onClick={()=>set_show_more((show_more)=> !show_more)}>{show_more?'Less':'More'}</span>
          <section className={show_more?'show':''}>
            {list.slice(MAXIMUM).map((eLink)=>
              <EachLink key={eLink.id} {...{...eLink,set_show_more}} minimize={minimize} />
            )}
            <span><ShowIcon /></span>
          </section>

        </section>}

      </div>

    </Wrapper>
  )
}

export default memo(Sidenav);


const EachLink = (props) =>{
  const path = props.path;
  const name = props.name;
  const icon = props.icon ? props.icon:'';
  const minimize = props.minimize;
  const location = useLocation();
  const [active,set_active] = useState(false);
  const set_show_more = props.set_show_more;
  const getClass=() =>{
    let {pathname} = location;
    // console.log(pathname,path,'path')
    if(pathname){
      let p_name = pathname.split('/')[1];
      if(p_name){
        return p_name.includes(path)?'active':''
      }
      else{
        return  path == '/'?'active':'';
      }
    } 
  }
  const getSubClass = (path) =>{
    let {pathname} = location;
    if(pathname){
      let p_name = pathname.split('/')[2];
      // console.log(p_name,path,'test')
      if(p_name){
        return p_name.includes(path)?'active':''
      }
      else{
        return path == 'all-kpi'?'active':'';
      }
    
    }
  }
  const handleActive = () =>{
     let mobile = window.innerWidth < 800;
     if(mobile) set_active(!active);
     else set_active(true);
  }
  return(
    <Div  title={`${name} Tab`} onClick={()=>{set_show_more(false);handleActive()}}>
      <Link to={path} className={getClass()}  >
        <span></span>
        <span>{icon}</span>
        
        <span className='animate_s_links' style={!minimize?{width:'fit-content'}:{width:'0'}}>
          {name}
        </span> 
        {
          props.sub_list && props.sub_list.length > 0 ?
            <span className='arrow'>
              {getClass() === 'active' && active? <UpIcon/>:<DownIcon/>} 
            </span>
          :''
        }
        
      </Link>
      { props.sub_list && props.sub_list.length > 0 && getClass() === 'active' && active ?<div>
        { 
          props.sub_list.map(eLink => 
          <Link key={eLink.id} to={`${path}/${eLink.path}`} className={getSubClass(eLink.path)}>
            <span><DotIcon /></span>
            <span>{eLink.name}</span>
          </Link>)
        }
      </div>:''}
      
    </Div>
  )
}

const Container = (props) =>{
  const children = props.children;
  return (<aside {...props}>{children}</aside>)
}

const DivContainer = (props) =>{
  const children = props.children;
  return (<div {...props}>{children}</div>)
}


const Wrapper = styled(Container)`
  position:relative;
  width:${props => props.minimize?'45px':'220px'};
  transition: all 0.5s linear;
  flex-shrink: 0;
  background-color: ${({theme}) => theme.background.primary};
  box-shadow: 0px 0 6px 1px rgba(0,0,0,0.05);
  display: flex;
  z-index:1;
  flex-direction: column;
  height: 100%;
  & > div:nth-of-type(2){
    &::-webkit-scrollbar {
      width: 0px;
    }
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: clip auto;
    width:100%;
    background-color: ${({theme})=> theme.background.primary};
    
    & > div{
      & > a{
        width:100%;
        padding:10px 0;
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: ${({theme})=> theme.background.primary};
        gap:10px;
        & > span{
          color:${({theme})=> theme.color.text};
        }
        & > span:first-of-type{
          display: inline-block;
          width:5px;
          height:20px;
          background-color: transparent;
        }
        & > span:nth-of-type(2){
          display: flex;
          align-items: center;
          justify-content: center;
        }
        & > span:nth-of-type(3){
          color:${({theme})=> theme.color.text};
          display: inline-block;
          overflow: hidden;
          height:21px;
        }
        & > span.arrow{
          display: flex;
          justify-content: center;
          align-items: center;
          margin:-2px 5px 0 auto;
        }
        &.active{
          background-color:var(--high-light-blue) ;
          & > span{
            color:${({theme})=> theme.color.primary};
            &:first-of-type{
              background-color: ${({theme})=> theme.color.primary} !important;
            }
            &:nth-of-type(3){
              font-weight:500;
            }
          }
        }
        &:hover{
          background-color:var(--high-light-blue) ;
          & > span{
            color:${({theme})=> theme.color.primary};
            &:first-of-type{
              background-color: ${({theme})=> theme.color.primary} !important;
            }
          }
        }
      }
    }
   
    & > section{
      display: flex;
      background-color: ${({theme})=> theme.background.primary};
      flex-direction: column;
      width:100%;
      & > span{
        display: none;
      }
      & > section{
        display:flex;
        flex-direction: column;
        width:100%;
        & > div{
            & > a{
            padding:10px 0;
            display: flex;
            align-items: center;
            text-decoration: none;
            gap:10px;
            & > span{
              color:${({theme})=> theme.color.text};
            }
            & > span:first-of-type{
              display: inline-block;
              width:5px;
              height:20px;
              background-color: transparent;
            }
            & > span:nth-of-type(2){
              display: flex;
              align-items: center;
              justify-content: center;
            }
            & > span:nth-of-type(3){
              color:${({theme})=> theme.color.text};
              display: inline-block;
              overflow: hidden;
              height:21px;
            }
            & > span.arrow{
              display: flex;
              justify-content: center;
              align-items: center;
              align-self: flex-end;
              margin-right: 5px;
            }
            &.active{
              background-color:var(--high-light-blue) ;
              & > span{
                color:${({theme})=> theme.color.primary};
                &:first-of-type{
                  background-color: ${({theme})=> theme.color.primary} !important;
                }
                &:nth-of-type(3){
                  font-weight:500;
                }
               
              }
            }
            &:hover{
              background-color:var(--high-light-blue) ;
              & > span{
                color:${({theme})=> theme.color.primary};
                &:first-of-type{
                  background-color: ${({theme})=> theme.color.primary} !important;
                }
              }
            }
          }
        }
        & > span{
          display:none;
        }
      }
      
    }
    
  }


  @media screen and (width <= 800px) {

    width:100%;
    box-shadow: none;
    height:auto ;
    
    & > div:first-of-type{
      display:none !important;
    }
    & > div:nth-of-type(2){
      flex-direction: row;
      width:100dvw;
      & > div{
        flex-grow:1;
        & > a{
        height:100%;
        
        display:flex;
        padding:0 0 10px;
        flex-direction: column;
        & > span:first-of-type{
            width:100%;
            height:3px;
        }
        & > span:nth-of-type(2){
          margin-top:auto;
         
        }
        & > span:nth-of-type(3){
          display: none;
         
        }
        & > span.arrow{
          margin:0;
          display:none;
         
        }
        }
      }
      & > section{
        width:50px;
        flex-shrink: 0;
        position: relative;
        height: 39px;
        justify-content: center;
        align-items: center;
        & > span{
          display:inline-block;
          cursor: pointer;
        }
        
        & > section{
          display:none;
          align-items: center;
          position: absolute;
          bottom:39px;
          width:fit-content;
          right:0;
          border-bottom: 1px solid ${props => props.theme.color.border};
          background-color: ${props => props.theme.background.primary};
          &.show{
            display: flex;
          }
          
          & > span{
            display: inline-block;
            position: relative;
            width:20px;
            height:12px;
            align-self:flex-end;
            color:${props => props.theme.color.text};
            margin-right:15px;
          }
          & > div{
            display:flex;
            width:100%;
            & > a{
              width:100%;
              padding-right: 10px;;
            }
            
          
          }

        }
      }
      
      
      
    }
  }

`
const Div = styled(DivContainer)`
  position:relative;
  & > div{
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${props => props.theme.color.border};
    border-bottom: 1px solid ${props => props.theme.color.border};
    & > a{
      color:${({theme})=> theme.color.text};
      padding:5px 0;
      font-size:13px;
      text-decoration: none;
      padding-left: 15px;
      display: flex;
      align-items: center;
      gap:10px;
      &.active{
        background-color:var(--high-light-blue) ;
      }
    }
    
  }
  @media screen and (width <= 800px) {
    & > div{
      position:absolute;
      bottom:39px;
      background-color: ${props => props.theme.background.primary};
      right:0;
      width:180px;
    }
  }
  
  
`