import { useState,useRef,useEffect,memo} from 'react';
import {IoSettings as SettingsIcon} from 'react-icons/io5';
import styled from '@emotion/styled';
import {MdAddCircleOutline as AddIcon} from 'react-icons/md';
import {TbSwitchHorizontal as SwitchBusinessIcon,TbHomeEdit as EditBusinessIcon} from 'react-icons/tb';
import {Switch} from '../';
import { AddBusiness,Businesses } from '../../modal';
import useModal from '../../hooks/useModal';
import useApi from '../../hooks/useApi';
import useNotification from '../../hooks/useNotification';
import { useSelector } from 'react-redux';

const Settings = ({toggleMode,theme}) => {
    const [show,set_show] = useState(false);
    const {cookies} = useApi();
    const spanRef = useRef();
    const divRef = useRef();
    const {showModal} = useModal();
    const {showNotification} = useNotification();
    const all_business = useSelector(store => store.hrState.businesses);
    
    const handleClose =() =>{
        set_show(false);
    }
    const clickModeSwitch = () =>{
        toggleMode();
    }    

    const handleAddBusiness = ()=>{
        showModal({content:<AddBusiness />});
        set_show(false);
    }

    const handleEditBusiness = () =>{
        let data = all_business.find(eBusiness => eBusiness.id === cookies.user.org.id);
        console.log(cookies.user.org,'business data')
        showModal({content:<AddBusiness {...data} />})
    }

    const handleSwitchBusiness = () =>{
        showModal({content:<Businesses />});
    }
    
    const list = [
        {
            id:1,name:"Add Business",
            icon:<AddIcon />,onClick:handleAddBusiness},
        {
            id:2,name:"Switch Business",
            icon:<SwitchBusinessIcon />,
            onClick:handleSwitchBusiness
        },
        {
            id:3,name:"Edit Business",
            icon:<EditBusinessIcon />,
            onClick:handleEditBusiness,
        },
        {
            id:4,name:"Screen Mode",
            icon:<Switch {...{mode:(theme === 'light'?false:true)}}/>,onClick:clickModeSwitch
        },
    ]

    useEffect(()=>{
        const handleOutsideClick = (e)=>{
            if(spanRef && spanRef.current && divRef && divRef.current){
                const spanDimensions = spanRef.current.getBoundingClientRect();
                const divDimensions = divRef.current.getBoundingClientRect();
                if( e.clientX < divDimensions.left ||
                    e.clientX > spanDimensions.right ||
                    e.clientY < spanDimensions.top ||
                    e.clientY > divDimensions.bottom){
                        handleClose();
                }   
            }
        }
        window.addEventListener('click',handleOutsideClick);

        return ()=> window.removeEventListener('click',handleOutsideClick);
    },[])
    
  return (
    <Wrapper>
        <span title="Settings" ref={spanRef} onClick={()=>set_show(!show)} style={show?{color:'var(--primary-color)'}:{color:'var(--light-blue)'}}><SettingsIcon /></span>
        {show ?<div ref={divRef}>
            {/* <span className='cancel' onClick={()=>set_show(false)}><CancelIcon /></span> */}
            {list.map(eList => <a key={eList.id} onClick={eList.onClick?eList.onClick:()=>{}}>
                <span>{eList.icon || ''}</span>
                {eList.name}

            </a>)}

        </div>:<></>}
    </Wrapper>
  )
}

export default memo(Settings);

const Wrapper = styled.div`
    position:relative;
    background-color: #fafafa;
    width:40px;
    height:40px;
    border-radius: 50%;
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    & > span{
        cursor:pointer;
        font-size:20px;
        color:var(--light-blue);
    }
    & > div{
        position: absolute;
        top:22px;
        right:0px;
        border: 1px solid ${({theme})=> theme.color.dropBorder};
        @media screen and (width <= 800px) {
            right:-40px;
        }
        
        width:200px;
        box-shadow: var(--modal-shadow);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        background-color: ${({theme})=> theme.background.primary};
        padding:10px 0;
        z-index: 1;
        & > span.cancel{
            cursor:pointer;
            align-self: flex-end;
            color:var(--red-color);
            margin-right:10px;
        }
        & > a{
            padding:10px;
            width:100%;
            display:flex;
            gap:10px;
            color: ${({theme})  => theme.color.text};
            align-items: center;
            font-size:14px;
            & > span:first-of-type{
                font-size:16px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: fit-content;
            }
            &:hover{
                background-color: var(--light-blue);
                cursor: pointer;
                color:var(--white-color)
            }

        }
    }


`