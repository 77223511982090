import { useState, useEffect, memo } from "react";
import styled from "@emotion/styled";
import useModal from "../../hooks/useModal";
// import { addKpi,editKpi as EditKpi } from '../../redux/reducers/hrReducer';
import useApi from "../../hooks/useApi";
import useNotification from "../../hooks/useNotification";
import useLoading from "../../hooks/useLoading";
//import { useDispatch } from 'react-redux';
import {
  addKpiGroup,
  editKpiGroup as editKpiGroupDetail,
} from "../../redux/reducers/kpiReducer";
import { useDispatch, useSelector } from "react-redux";

const AddKpiGroup = ({ editKpiGroup = null }) => {
  const [title, set_title] = useState("");
  const [description, set_description] = useState("");
  const { showModal } = useModal();
  const { Api } = useApi();
  const { loading } = useLoading();
  const { showNotification } = useNotification();
  const dispatch = useDispatch();

  const clearForm = () => {
    set_title("");
    set_description("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title && description) {
      const body = {
        title: title,
       // description: description,

        // business_id: cookies.user.org.id,
      };
      if (editKpiGroup) body.id = editKpiGroup.id;
      if (Api.check_network()) {
        const url = editKpiGroup ? "kpi/edit-kpi-group" : "kpi/create-kpi-group";
        loading();
        Api.post({ url, body })
          .then((data) => {
            loading(true);
            if (data.isSuccessful) {
              editKpiGroup
                ? dispatch(
                    editKpiGroupDetail({
                      id: editKpiGroup.id,
                      title,
                     // description,
                    })
                  )
                : dispatch(
                    addKpiGroup({
                      id: data.data.id,
                      title,
                      description,
                    })
                  );
              if (editKpiGroup) showModal({ close: true });
              clearForm();

              showNotification({
                type: "success",
                message:
                  data.message || editKpiGroup
                    ? "KpiGroup Edited succesfully"
                    : "KpiGroup Create Successfully",
              });
            } else {
              showNotification({
                type: "error",
                message: data.message || "Error occured try again later",
              });
            }
          })
          .catch((err) => {
            loading(true);
            showNotification({
              type: "error",
              message: err.message || "An Error occured",
            });
          });
      } else {
        showNotification({
          type: "error",
          message: "No Internet Connectivity",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Fill All required fields",
      });
    }
  };
  useEffect(() => {
    if (editKpiGroup) {
      set_title(editKpiGroup.title);
      set_description(editKpiGroup.description);
    }
  }, [editKpiGroup]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <h2>{editKpiGroup ? "Edit Kpi Group" : "Create Kpi Group"}</h2>

        <label>
          <span>Title*</span>
          <input
            type="text"
            placeholder="Enter Title"
            value={title}
            onChange={(e) => set_title(e.target.value)}
          />
        </label>
        <label>
          <span>Description*</span>
          <textarea
            type="text"
            inputMode="text"
            placeholder="Enter description"
            value={description}
            onChange={(e) => set_description(e.target.value)}
          ></textarea>
        </label>

        <button>{editKpiGroup ? "Save Edit Event" : "Submit"}</button>
      </form>
    </Wrapper>
  );
};

export default memo(AddKpiGroup);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* text-align: center; */
  width: 600px;
  @media screen and (width <= 650px) {
    width: 96%;
  }
  padding: 0 10px 20px;
  position: relative;

  & > form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    & > span {
      font-size: 25px;
      top: 0;
      right: 0;
      position: absolute;
      color: red;
      cursor: pointer;
    }
    & > h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    & > div:first-of-type {
      display: flex;
      align-items: flex-end;
      gap: 20px;
      width: 100%;
      @media screen and (width <=650px) {
        flex-direction: column;
        gap: 10px;
      }
      & > label {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 14px;
        width: 50%;
        @media screen and (width <=650px) {
          width: 100%;
        }
      }
    }

    & > label {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 14px;
    }
    & > div:not(:first-of-type) {
      display: flex;
      gap: 20px;
      @media screen and (width <=650px) {
        flex-direction: column;
      }

      & > label {
        display: flex;
        flex-direction: column;
        gap: 3px;
        flex: 1;

        & > span {
          font-size: 14px;
        }
      }
    }

    & input:not([type="file"]),
    & select,
    & textarea {
      outline: none;
      border: 1px solid ${({ theme }) => theme.color.primary};
      background-color: transparent;
      width: 100%;
      padding: 8px 5px;
      border-radius: 4px;
    }

    i {
      color: grey;
      font-size: 13px;
    }

    & > button {
      background-color: var(--primary-color);
      padding: 10px 0;
      border: none;
      border-radius: 4px;
      color: var(--white-color);
      font-size: 17px;
      margin-top: 10px;
    }
  }
`;
